/* eslint-disable no-unused-vars */
import queryString from 'query-string';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Table, Tooltip } from 'antd';
import { createStyles } from 'antd-style';
import moment from 'moment';
import { getOrganizationValuesAction, getOrganizationValuesRefreshAction, getMoreOrganizationValuesAction } from '../Admin/logic';
import UpdateOrg from '../UpdateOrg';
import DeleteOrg from '../DeleteOrg';
import { setCookie, rolesToAppName } from '../../../utils';
import LazyLoadDiv from '../../../common/components/LazyLoadDiv';
import Loader from '../../../common/components/Loader';

let timer = null;
const debounceCall = (callback, debounceTime) => {
  if (timer) {
    clearTimeout(timer);
  }
  timer = setTimeout(() => {
    callback();
  }, debounceTime);
};

const useStyle = createStyles(({ css, token }) => {
  const { antCls } = token;
  return {
    customTable: css`
      ${antCls}-table {
        ${antCls}-table-container {
          ${antCls}-table-body,
          ${antCls}-table-content {
            scrollbar-width: thin;
            scrollbar-color: unset;
          }
        }
      }
    `,
  };
});

export default function OrgTable({ search, update }) {
  const dispatch = useDispatch();
  const { styles } = useStyle();

  const [openCreateOrg, setOpenCreateOrg] = useState(false);
  const [orgEditData, setOrgEditData] = useState({});
  const [orgDataDeleteID, setOrgDataDeleteiD] = useState('');
  const [currentPage, setCurrentPage] = useState(1);

  const organizationValues = useSelector((state) => state.organizationValues);

  const baseWidth = window.innerWidth - 150;

  const getWidth = (percent) => (percent * baseWidth) / 100;

  const loadList = (page) => {
    const request = {
      from: page,
      size: 100,
      search,
    };
    if (page === 1) {
      dispatch(getOrganizationValuesAction(queryString.stringify(request)));
    } else {
      debounceCall(() => dispatch(getMoreOrganizationValuesAction(queryString.stringify(request))), 100);
    }
    setCurrentPage((current) => current + 1);
  };

  useEffect(() => {
    dispatch(getOrganizationValuesRefreshAction());
    setCurrentPage(1);
    loadList(1);
  }, [update, search]);

  const handleOnEditClick = (data) => {
    setOrgEditData({ ...data });
  };

  useEffect(() => {
    if (openCreateOrg) {
      setCookie('edit', 'orgUpdateType');
    }
  }, [openCreateOrg]);

  const handleSetOpenCreateOrg = (value) => {
    setOpenCreateOrg(value);
    if (!value) {
      setOrgEditData({ ...{} });
      debounceCall(() => dispatch(getOrganizationValuesAction(queryString.stringify({
        search,
        size: 100,
        from: 1,
      }))), 100);
    }
  };

  const handleOrgDataUpdate = (data) => {
    setOrgDataDeleteiD(data.organization_id);
  };

  const renderTooltip = (data) => (
    <div className="tooltip-org flex flex-col gap-2">
      <div aria-hidden onClick={() => handleOnEditClick(data)} className="flex gap-2 pointer container-edit-org">
        <div className="edit-icon" />
        <div>Edit</div>
      </div>
      <div aria-hidden onClick={() => handleOrgDataUpdate(data)} className="flex gap-2 pointer container-edit-org">
        <div className="delete-icon" />
        <div>Delete</div>
      </div>
    </div>
  );

  const convertTimestampToGMT = (timestamp) => {
    // if (timestamp === 0 && data.userOnboardingType === 'Email') {
    //   return (
    //     <div>
    //       <div className="show-date">
    //         {flag && !data?.userStatus?.includes('Not onboarded') && (
    //           <div
    //             aria-hidden
    //             onClick={() => handleCurrentAccess(data)}
    //             className="edit-icon-terminal"
    //           />
    //         )}
    //       </div>
    //     </div>
    //   );
    // }
    const date = moment(Number(timestamp));

    if (date.isValid() && timestamp !== 0) {
      const getDate = date.format('DD MMM, YYYY');
      const getTime = date.format('hh:mm A');

      return (
        <div>
          <div className="show-date">
            {getDate}
            {' '}
            {/* {flag && !data?.userStatus?.includes('Not onboarded') && (
              <div
                aria-hidden
                onClick={() => handleCurrentAccess(data)}
                className="edit-icon-terminal"
              />
            )} */}
          </div>
          <div>{getTime}</div>
        </div>
      );
    }
    return null;
  };

  const columns = [
    {
      title: 'Organization name',
      dataIndex: 'organization_name',
      key: 'organization_name',
      sorter: (a, b) => {
        const valueA = a.organization_name.toLowerCase();
        const valueB = b.organization_name.toLowerCase();

        return valueA.localeCompare(valueB);
      },
      width: getWidth(20),
    },
    {
      title: 'Organization type',
      dataIndex: 'organization_type',
      key: 'organization_type',
      sorter: (a, b) => {
        const valueA = a.organization_type.toLowerCase();
        const valueB = b.organization_type.toLowerCase();

        return valueA.localeCompare(valueB);
      },
      width: getWidth(10),
    },
    {
      title: 'Domain',
      dataIndex: 'domain',
      key: 'domain',
      width: getWidth(5),
    },
    {
      title: 'License start date and time',
      dataIndex: 'license_start_date',
      key: 'license_start_date',
      render: (a) => {
        if (a) {
          return convertTimestampToGMT(a);
        }
        return null; // Return null if the key is absent
      },
      width: getWidth(15),
    },
    {
      title: 'License end date and time',
      dataIndex: 'license_end_date',
      key: 'license_end_date',
      render: (a) => {
        // Check if 'a' is defined and has 'license_start_date'
        if (a) {
          return convertTimestampToGMT(a);
        }
        return null; // Return null if the key is absent
      },
      width: getWidth(15),
    },
    {
      title: 'License period',
      dataIndex: 'period',
      key: 'period',
      render: (a) => (a === 0 ? null : a),
      width: getWidth(10),
    },
    {
      title: 'List of features/Terminals',
      dataIndex: 'terminals',
      key: 'terminals',
      render: (a) => (
        <div className="termainls-col">

          {a?.map((terminal) => (rolesToAppName[terminal] ? (
            <div className="terminal-box" key={terminal}>
              {rolesToAppName[terminal]}
            </div>
          ) : null))}
        </div>
      ),
      width: getWidth(15),
    },
    {
      width: getWidth(5),
      render: (a) => (
        <div>
          <Tooltip
            placement="left"
            overlayClassName="prediction-tooltip position-zero"
            title={() => renderTooltip(a)}
            arrow={false}
          >
            <div className="three-dots-org pointer" />
          </Tooltip>
        </div>
      ),
    },
  ];

  const handleChange = (pagination, filters, sorter) => {
    let sortQuery = {};
    if (sorter.order && sorter.columnKey) {
      sortQuery = {
        search,
        sort: sorter.columnKey,
        order: sorter.order === 'ascend' ? 'asc' : 'desc',
        from: 1,
        size: 100,
      };
      debounceCall(() => dispatch(getOrganizationValuesAction(queryString.stringify({ ...sortQuery }))), 100);
    }
  };

  return (
    <div>
      <Loader
        error={organizationValues.error}
        loading={organizationValues.loading}
        height="500px"
        className="white-loader"
        message="No records found"
      >
        <LazyLoadDiv
          className="explore-list-results"
          id="org-list-3"
          total={organizationValues?.total}
          currentTotal={(organizationValues.data || []).length}
          height="calc(100vh - 200px)"
          loadMoreRows={() => loadList(currentPage)}
        >
          <Table
            borderec
            className={styles.customTable}
            columns={columns}
            dataSource={organizationValues?.data.filter((item) => !item.is_deleted)}
            scroll={{
              x: 'max-content',
            }}
            sticky
            pagination={false}
            showSorterTooltip={false}
            onChange={handleChange}
          />
        </LazyLoadDiv>
      </Loader>
      <UpdateOrg orgData={orgEditData} open={openCreateOrg} setOpen={(value) => handleSetOpenCreateOrg(value)} type="edit" loadData={() => loadList(1)} search={search} />
      <DeleteOrg orgDataID={orgDataDeleteID} setOrgDataIDEmpty={() => setOrgDataDeleteiD('')} loadData={() => loadList(1)} />
    </div>
  );
}
