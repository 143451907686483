import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import queryString from 'query-string';
import { Modal } from 'antd';
import { deleteOrgAction, getAssociatedUsersAction, getAssociatedUsersRefresh } from './logic';
import SvgLoader from '../../../common/components/SvgLoader';
import { sendNotification } from '../../../common/components/Notification/logic';
import AdminFilterAutoSuggest from '../AdminFilterAutoSuggest';
import { getOrganizationValuesRefreshAction } from '../Admin/logic';

export default function DeleteOrg({ orgDataID, setOrgDataIDEmpty, loadData }) {
  const dispatch = useDispatch();

  const [openNoUserAssociatedModal, setOpenNoUserAssociatedModal] = useState(false);
  const [associatedUser, setAssociatedUser] = useState(-1);
  const [openAssociatedUserModal, setAssociatedUserModal] = useState(false);
  const [selectedOrgId, setSelectedOrgId] = useState([]);
  const [noOrgSelectedError, setNoOrgSelectedError] = useState(false);

  const associatedUsers = useSelector((state) => state.associatedUsers);
  const deleteOrgStatus = useSelector((state) => state.deleteOrgStatus);

  const defaultState = () => {
    setOrgDataIDEmpty();
    setOpenNoUserAssociatedModal(false);
    setAssociatedUserModal(false);
    setNoOrgSelectedError(false);
  };

  const handleUpdateOrg = (orgId) => {
    setNoOrgSelectedError(false);
    setSelectedOrgId([...orgId]);
  };

  useEffect(() => {
    if (deleteOrgStatus.flag) {
      if (deleteOrgStatus.data.data === 'Organization deleted successfully') {
        dispatch(sendNotification({
          type: 'ORG_DELETE_SUCCESS',
          notificationType: 'success',
          message: 'Organization deleted successfully',
          showNotification: true,
        }));
        dispatch(getOrganizationValuesRefreshAction());
        loadData();
      }
    }
  }, [JSON.stringify(deleteOrgStatus)]);

  useEffect(() => {
    if (associatedUsers.flag) {
      if (associatedUsers.data.total === 0) {
        setOpenNoUserAssociatedModal(true);
        dispatch(getAssociatedUsersRefresh());
      } else {
        setAssociatedUser(associatedUsers.data.total);
        dispatch(getAssociatedUsersRefresh());
        setAssociatedUserModal(true);
      }
    }
  }, [JSON.stringify(associatedUsers)]);

  useEffect(() => {
    if (orgDataID) {
      dispatch(getAssociatedUsersAction({
        headers: queryString.stringify({
          organization_id: orgDataID,
        }),
      }));
    } else {
      defaultState();
    }
  }, [orgDataID]);

  const handleDeleteClick = (type) => {
    if (type === 'has-user') {
      if (selectedOrgId.length) {
        dispatch(deleteOrgAction({
          headers: queryString.stringify({
            organization_id: orgDataID,
            updated_organization_id: selectedOrgId[0],
            is_deleted: true,
          }),
        }));
        defaultState();
      } else {
        setNoOrgSelectedError(true);
      }
    }
    if (type === 'no-user') {
      dispatch(deleteOrgAction({
        headers: queryString.stringify({
          organization_id: orgDataID,
          updated_organization_id: orgDataID,
          is_deleted: true,
        }),
      }));
      defaultState();
    }
  };

  const renderAssocatedUserModal = () => (
    <Modal className="filter-modal-container" footer={false} open={openAssociatedUserModal} closeIcon={false} width={770}>
      <div>
        <div className="filter-header">
          <div>{' '}</div>
          <SvgLoader onClick={() => defaultState()} pointer width={14.7} height={14.7} svgName="modal-cross" hoverIconName="modal-cross-hover" />
        </div>
        <div className="access-update-text">
          <div>{`${associatedUser} users are associated with this organization.`}</div>
          <div>Please select other organization to associate users </div>
          <div className="org-note">Note: All users will be moved to selected organization and current organization will be deleted.</div>
          <div className="flex items-center py-8">
            <AdminFilterAutoSuggest
              reset={false}
              filter={{
                label: 'Organization Name',
                type: 'autosuggest-multi',
                key: 'organization_name',
              }}
              className={['autosuggest-multi', 'autosuggest-panel-filter', 'autosuggest-options']}
              type="org-delete"
              selectedOrgId={(updates) => handleUpdateOrg(updates)}
            />
          </div>
        </div>
        <div className="flex items-center justify-center pb-4">
          <div className="not-valid">{noOrgSelectedError ? 'Please select an organization to move the users' : null}</div>
        </div>
        <div className="filter-buttons">
          <div aria-hidden onClick={() => defaultState()} className="reset-all-btn pointer">Cancel</div>
          <div aria-hidden onClick={() => handleDeleteClick('has-user')} className="filter-btn pointer">Ok</div>
        </div>
      </div>
    </Modal>
  );

  const noUserModal = () => (
    <Modal className="filter-modal-container" footer={false} open={openNoUserAssociatedModal} closeIcon={false} width={770}>
      <div>
        <div className="filter-header">
          <div>{' '}</div>
          <SvgLoader onClick={() => defaultState()} pointer width={14.7} height={14.7} svgName="modal-cross" hoverIconName="modal-cross-hover" />
        </div>
        <div className="access-update-text" style={{ height: '200px' }}>
          <div>No users associated to this organization.</div>
          <div>Are you sure you want to delete the organization?</div>
        </div>
        <div className="filter-buttons">
          <div aria-hidden onClick={() => defaultState()} className="reset-all-btn pointer">Cancel</div>
          <div aria-hidden onClick={() => handleDeleteClick('no-user')} className="filter-btn pointer">Ok</div>
        </div>
      </div>
    </Modal>
  );

  return (
    <div>
      {noUserModal()}
      {openAssociatedUserModal && renderAssocatedUserModal()}
    </div>
  );
}
