const adminFilterDetails = [
  {
    label: 'Organization Name',
    type: 'autosuggest-multi',
    key: 'organization_name',
  },
  {
    label: 'User Onboarding Type',
    type: 'multiselect',
    options: [
      { label: 'Email', value: 'email' },
      { label: 'User name & password', value: 'username-password' },
    ],
    key: 'onboarding_type',
  },
  {
    label: 'User Account Type',
    type: 'multiselect',
    options: [
      { label: 'Free User', value: 'free' },
      { label: 'Enterprise User', value: 'enterprise' },
      { label: 'Paid User', value: 'paid' },
      { label: 'Internal User', value: 'internal' },
      { label: 'Internal Team User', value: 'team' },
      { label: 'Internal Admin User', value: 'admin' },
    ],
    key: 'account_type',
  },
  {
    label: 'Current Access Date and Time',
    type: 'date-time-range',
    key: 'license_date',
  },
  {
    label: 'First Name, Last Name or Email',
    type: 'input',
    key: 'user_name',
  },
  {
    label: 'User Status',
    type: 'multiselect',
    options: [
      { label: 'Active', value: 'Active' },
      { label: 'Inactive', value: 'Inactive' },
      { label: 'Registered - Not onboarded', value: 'Registered - Not onboarded' },
      { label: 'Invited - Not onboarded', value: 'Invited - Not onboarded' },

    ],
    key: 'user_status',
  },
];

export const filtersKeys = ['organization_name', 'onboarding_type', 'account_type', 'user_name', 'license_date', 'user_status'];

export default adminFilterDetails;
