import Rcookie from 'react-cookies';
import queryString from 'query-string';
import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import CryptoJS from 'crypto-js';
import moment from 'moment';

export const PATHS = [
  ...Object.keys(process.env.APPS).map((item) => process.env.APPS[item].mainRoute),
];

export const getCurrentDateTime = () => {
  const now = moment();
  const formattedDate = now.format('DD MMM, YYYY');
  const formattedTime = now.format('hh:mm A');

  return {
    date: formattedDate,
    time: formattedTime,
  };
};

export const setCookie = (value, key) => {
  const { domain } = process.env;
  const expires = new Date();
  expires.setDate(new Date().getDate() + 15);
  Rcookie.save(`${process.env.app}_${key}_${process.env.type}`, value, { path: '/', domain, expires });
};

export const removeCookies = (flag) => {
  const cookies = document.cookie.split(';');
  const { domain } = process.env;
  for (let i = 0; i < cookies.length; i += 1) {
    const cookie = cookies[i];
    const eqPos = cookie.indexOf('=');
    const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    PATHS.forEach((item) => {
      if (flag) {
        if (name.includes(`${process.env.app}_`)) {
          document.cookie = `${name} =;expires=Thu, 01 Jan 1970 00:00:00 GMT ; domain=${domain}; path=${item}`;
        }
      } else {
        document.cookie = `${name} =;expires=Thu, 01 Jan 1970 00:00:00 GMT ; domain=${domain}; path=${item}`;
      }
      return 0;
    });
  }
};

export const getCookie = (key, app) => Rcookie.load(`${app || process.env.app}_${key}_${process.env.type}`);

export function ucFirst(value) {
  if (value) {
    const string = value.charAt(0).toUpperCase() + value.slice(1);
    const newString = string.replace('_', ' ');
    return newString;
  }
  return '';
}

export default function apiCall(url, method, body = {}, authReq = true, customHeaders = {}) {
  let obj = {};
  if (method === 'POST' || method === 'PUT' || method === 'DELETE') {
    obj = {
      method,
      url,
      body,
    };
  } else {
    obj = {
      method,
      url: `${url}${body && Object.keys(body).length ? `?${queryString.stringify(body)}` : ''}`,
    };
  }
  const headers = {
    Authorization: (Rcookie.load(`${process.env.app}_accessToken_${process.env.type}`) ? Rcookie.load(`${process.env.app}_accessToken_${process.env.type}`) : ''),
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': `${process.env.applicationUrl}`,
    ...customHeaders,
  };
  Object.entries(headers).forEach((en) => {
    if (en[1] === undefined || (!authReq && en[0] === 'Authorization')) {
      delete headers[en[0]];
    }
  });
  return ({
    ...obj,
    headers: { ...headers, ...customHeaders },
  });
}

export const checkPermission = (value) => {
  if (localStorage.getItem('permissions')) {
    return localStorage.getItem('permissions').indexOf(value) > -1;
  }
  return false;
};

export const makeFirstLetterCapital = (value) => {
  let remadeValue = '';
  if (!value) {
    return null;
  }
  const valueArr = value.toLowerCase().split(' ');
  valueArr.forEach((word) => {
    remadeValue += `${word.slice(0, 1).toUpperCase()}${word.slice(1)} `;
  });
  return remadeValue.trim();
};

export const modifyDataForDropdown = (data, labelKey, ValueKey) => {
  const options = data.map((item) => ({
    ...item,
    label: item[labelKey],
    value: item[ValueKey],
  }));
  return options;
};

let timer = null;
export const debounceCall = (callback, debounceTime) => {
  if (timer) {
    clearTimeout(timer);
  }
  timer = setTimeout(() => {
    callback();
  }, debounceTime);
};

export const wordLimit = (words, size, original = false) => {
  if (!words) {
    return null;
  }
  if (words.length > size) {
    let count = 0;
    const wordList = words.split(' ');
    let result = '';

    wordList.forEach((word) => {
      if (count + word.length > size) {
        return;
      }
      count += word.length + 1;
      result += `${word} `;
    });
    if (!result.length || original) {
      return `${words.slice(0, size)} . . .`;
    }
    return (
      <div>
        {`${result.trim()} . . .`}
      </div>
    );
  }
  return words;
};

export const trackOutSideEvents = (elementRef, action) => {
  const handler = (event) => {
    if (!elementRef.current) {
      return;
    }

    if (!elementRef.current.contains(event.target)) {
      action();
    }
  };

  document.addEventListener('click', handler, true);

  return () => {
    document.removeEventListener('click', handler);
  };
};

export const modifyDataForGetResultsQuery = (data, isSE, type) => {
  const request = {
    body: {},
  };
  data?.forEach((item) => {
    if (item?.currentKeys?.length > 0) {
      if (item.type !== 'number' && !request.body[item.name]) {
        request.body[item.name] = [];
      }
      if (item.type === 'date-range' || item.type === 'number-range') {
        item?.currentKeys?.forEach((filter, index) => {
          if (index === 0 && filter !== '') {
            request.body[item.name].push({
              value: `${filter}`,
              operator: 'GTE',
            });
          }
          if (index === 1 && filter !== '') {
            request.body[item.name].push({
              value: `${filter}`,
              operator: 'LTE',
            });
          }
          if (isSE && filter !== '') {
            if (request.body[item.name].length > 1) {
              request.body[item.name][index].asset_class = 'ot_search_assetclasses';
            } else {
              request.body[item.name][0].asset_class = 'ot_search_assetclasses';
            }
          }
          if (item.assetClass && request.body[item.name][index]) {
            request.body[item.name][index].asset_class = item.assetClass;
          }
          if (type === 'ctfilters' && filter !== '' && request.body[item.name][index]) {
            request.body[item.name][index].filterFrom = 'filter_search';
          }
        });
      } else if (item.name === 'gender') {
        if (item.currentKeys[0]?.length) {
          item.currentKeys[0].forEach((filter, i) => {
            request.body[item.name].push({
              value: filter,
              operator: 'OR',
            });
            // eslint-disable-next-line dot-notation
            if (isSE) {
              request.body[item.name][i].asset_class = 'ot_search_assetclasses';
            }
            if (item?.assetClass) {
              request.body[item.name][i].asset_class = item.assetClass;
            }
            if (type === 'ctfilters') {
              request.body[item.name][i].filterFrom = 'filter_search';
            }
          });
        }
      } else if (item.subAssetClass) {
        item?.currentKeys?.forEach((filter) => {
          request.body[item.name].push({
            value: filter,
            operator: 'OR',
            asset_class: 'regulatory_data',
            sub_asset_class: item.subAssetClass,
          });
        });
      } else if (item.type === 'free_text') {
        if (item.currentKeys[0].length > 0) {
          item?.currentKeys?.forEach((filter, i) => {
            request.body[item.name].push({
              value: filter,
              operator: 'OR',
            });
            if (isSE) {
              request.body[item.name][i].asset_class = 'ot_search_assetclasses';
            }
            if (item?.assetClass) {
              request.body[item.name][i].asset_class = item.assetClass;
            }
            if (type === 'ctfilters') {
              request.body[item.name][i].filterFrom = 'filter_search';
            }
          });
        }
      } else if (item.type === 'number') {
        if (item.currentKeys.length > 0) {
          item?.currentKeys?.forEach((filter, i) => {
            if (filter !== '') {
              request.body[item.subNames[i]] = [];
              request.body[item.subNames[i]].push({
                value: filter,
                operator: i === 0 ? 'GTE' : 'LTE',
              });
            }
            if (isSE) {
              request.body[item.subNames[i]][0].asset_class = 'ot_search_assetclasses';
            }
            if (item?.assetClass) {
              request.body[item.subNames[i]][0].asset_class = item.assetClass;
            }
          });
        }
      } else if (item.name === 'asset_classes') {
        item?.currentKeys?.forEach((filter) => {
          request.body[item.name].push({
            value: filter,
            operator: 'OR',
            asset_class: filter,
          });
        });
      } else if (item.name === 'primary_sponsor') {
        item?.currentKeys?.forEach((filter, i) => {
          request.body[item.name].push({
            value: filter,
            operator: item.op === 'industrial' ? 'OR' : 'NOT',
          });
          if (isSE) {
            request.body[item.name][i].asset_class = 'ot_search_assetclasses';
          }
          if (type === 'ctfilters') {
            request.body[item.name][i].filterFrom = 'filter_search';
          }
          if (item?.assetClass) {
            request.body[item.name][i].asset_class = item.assetClass;
          }
        });
      } else {
        item?.currentKeys?.forEach((filter, i) => {
          request.body[item.name].push({
            value: filter,
            operator: 'OR',
          });
          if (isSE) {
            request.body[item.name][i].asset_class = 'ot_search_assetclasses';
          }
          if (type === 'ctfilters') {
            request.body[item.name][i].filterFrom = 'filter_search';
          }
          if (item?.assetClass) {
            request.body[item.name][i].asset_class = item.assetClass;
          }
        });
      }
    }
  });
  return request;
};

export const getAllPermission = () => getCookie('permissions');

function convertUTCDateToLocalDate(date) {
  const newDate = new Date(date);
  return newDate;
}

export const formatDate = (timestamp) => {
  const monthNames = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'June',
    'July',
    'Aug',
    'Sept',
    'Oct',
    'Nov',
    'Dec',
  ];

  const dateObj = convertUTCDateToLocalDate(new Date(timestamp));
  const month = monthNames[dateObj.getMonth()];
  const date = String(dateObj.getDate()).padStart(2, '0');
  const year = dateObj.getFullYear();
  return `${date} ${month} ${year}`;
};

export const dateFormat = (timestamp) => {
  const months = [
    '01',
    '02',
    '03',
    '04',
    '05',
    '06',
    '07',
    '08',
    '09',
    '10',
    '11',
    '12',
  ];

  const dateObj = convertUTCDateToLocalDate(new Date(timestamp));
  const month = months[dateObj.getMonth()];
  const date = String(dateObj.getDate()).padStart(2, '0');
  const year = dateObj.getFullYear();
  return `${year}-${month}-${date}`;
};

export const modifeyNumber = (number, valueToFixed = 2) => {
  if (number >= 1000000000) {
    return `${(number / 1000000000).toFixed(valueToFixed)}B`;
  }

  if (number >= 1000000) {
    return `${(number / 1000000).toFixed(valueToFixed)}M`;
  }

  if (number >= 1000) {
    return `${(number / 1000).toFixed(valueToFixed)}K`;
  }
  return `${number}`;
};

export const eliminateCookies = () => {
  const arrSplit = document.cookie.split(';');

  for (let i = 0; i < arrSplit.length; i += 1) {
    const cookie = arrSplit[i].trim();
    const cookieName = cookie.split('=')[0];

    if (cookieName.includes('Ontosight-main_') || cookieName.includes('Ontosight_')) {
      document.cookie = `${cookieName}=;expires=Thu, 01 Jan 1970 00:00:00 GMT ;domain=.ontosight.ai;path=/`;
    }
  }
};

export const getTerminalName = ({ pathname }) => {
  if (pathname.includes('clinicalTrials')) {
    return 'Clinical Trials';
  }
  if (pathname.includes('intervention')) {
    return 'Intervention';
  }

  return 'Search and Explore';
};

export const getTerimalPage = ({ pathname }) => {
  if (pathname.includes('searchResults') || pathname.includes('results')) {
    return 'List View Page';
  }
  if (['/clinicalTrials', '/searchAndExplore', '/intervention'].includes(pathname)) {
    return 'Search Page';
  }
  if (pathname.includes('home')) {
    return 'Home Page';
  }

  return 'Analytics Page';
};

export const getAppliedFiltersName = (filtersApplied) => {
  const filtersName = [];

  const temp = filtersApplied?.filters?.filter((item) => item?.currentFilter);
  temp.forEach((item) => filtersName.push(item.name));

  return filtersName;
};

export const convertISOTimestampToTimestamp = (isoTimestamp) => {
  const date = new Date(isoTimestamp);

  const timestamp = date.getTime();

  return timestamp;
};

export const getTempSessionId = () => {
  if (getCookie('tempSesion')) {
    return getCookie('tempSesion');
  }

  const randomString = uuidv4();
  const hash = CryptoJS.SHA256(randomString).toString(CryptoJS.enc.Hex);
  setCookie(hash, 'tempSesion');
  return hash;
};

export function formatEpochTime(epochTime) {
  const date = new Date(epochTime * 1000);
  const options = {
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  };

  const formattedDate = date.toLocaleString('en-US', options);

  const [day, time] = formattedDate.split(', ');

  return `${day}, ${time}`;
}

export function formatEpochTimeToDayTime(epochTime) {
  const date = new Date(epochTime * 1000);
  const options = {
    weekday: 'short',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  };
  const formattedDate = date.toLocaleString('en-US', options);

  return formattedDate;
}

export const appPermission = {
  influence: 'influence_app_access',
  explore: 'xplore_app_access',
};

export const appRoles = {
  'Clinical Trials': 'clinical_trials',
  'Search And Explore': 'search_and_explore',
  Intervention: 'intervention',
  'Key Opinion Leaders': 'influence',
  'Bio-network': 'insight',
};

export const rolesToAppName = {
  clinical_trials: 'Clinical Trials',
  search_and_explore: 'Search And Explore',
  intervention: 'Intervention',
  influence: 'Key Opinion Leaders',
  insight: 'Bio-network',
};

export const availableApps = ['Clinical Trials', 'Search And Explore', 'Intervention', 'Key Opinion Leaders', 'Bio-network'];

// allowedPaths are those paths for which the login OTP modal will not open, these paths are allowed to open without login
export const allowedPaths = [
  '/',
  '/home',
  '/homeV2',
  '/persona',
  '/contact',
  '/callback',
  '/privacyPolicy',
  '/userPolicy',
  '/clinicalTrials',
  '/explore',
  '/intervention',
  '/searchAndExplore',
];

export const windowHrefToNavigate = (path) => {
  if (path[0] === '/') {
    window.location.href = `${process.env.redirectUri}${path}`;
  } else {
    window.location.href = `${process.env.redirectUri}/${path}`;
  }
};
