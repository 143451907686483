import queryString from 'query-string';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { Modal } from 'antd';
import { checkUserAction, checkUserRefreshAction } from '../../container/TryOntosightModal/logics';
import { setCookie, windowHrefToNavigate } from '../../../utils';
import { updatePrefilledEmailDataAction } from '../../container/LandingPageHeader/logic';
import SvgLoader from '../../../common/components/SvgLoader';

let timer = null;
const debounceCall = (callback, debounceTime) => {
  if (timer) {
    clearTimeout(timer);
  }
  timer = setTimeout(() => {
    callback();
  }, debounceTime);
};

export default function CaptureEmail() {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const email = searchParams.get('utm_content');

  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  const checkUser = useSelector((state) => state.checkUser);
  const prefilledEmailStatus = useSelector((state) => state.prefilledEmailStatus);

  const processEmail = (inputEmail) => {
    if (!inputEmail) {
      return null;
    }
    const plusIndex = inputEmail.indexOf('+');
    if (plusIndex !== -1) {
      return inputEmail.substring(0, plusIndex) + inputEmail.substring(inputEmail.indexOf('@'));
    }
    return inputEmail;
  };

  useEffect(() => {
    if (checkUser.flag) {
      setCookie(checkUser.data.isPersonaBuild, 'personaBuild');
      if (checkUser.data.registrationStatus === 'rejected') {
        setCookie(processEmail(email), 'userEmail');
        setCookie(true, 'rejectedApprovedl');
        windowHrefToNavigate('/home');
      }

      if (checkUser.data.userExists || checkUser.data.isPersonaBuild) {
        if (checkUser.data.isPersonaBuild === false) {
          setCookie(processEmail(email), 'userEmail');
          setCookie(false, 'personaBuild');
          windowHrefToNavigate('/persona');
        }
        setCookie(processEmail(email), 'userEmail');
        window.location.href = `${process.env.redirectUri}/home`;
      } else if (checkUser.data.isUserAllowed && checkUser.data.isEnterpriseUser) {
        dispatch(checkUserRefreshAction());
        setCookie(processEmail(email), 'userEmail');
        setCookie(false, 'personaBuild');
        windowHrefToNavigate('/persona');
      } else if (!checkUser.data.userExistsByOrgEmail) {
        dispatch(checkUserRefreshAction());
        dispatch(updatePrefilledEmailDataAction({
          modalType: 'try-ontosight',
          prefilledEmail: email,
          userType: 'individual',
        }));
      } else {
        setShowConfirmationModal(true);
      }
    }
  }, [JSON.stringify(checkUser)]);

  if (email && !checkUser.flag && prefilledEmailStatus.modalType === '') {
    debounceCall(() => {
      dispatch(checkUserAction({
        headers: queryString.stringify({
          email: email.toLowerCase(),
        }),
      }));
    }, 200);
  }

  const baseWidth = (window.innerWidth * 55) / 100;
  const baseHeight = baseWidth * 0.8;

  const imageSize = {
    width: baseWidth / 2,
  };

  if (showConfirmationModal) {
    return (
      <Modal className="login-modal-ctr" onCancel={() => setShowConfirmationModal(false)} footer={false} open={showConfirmationModal} closeIcon={false} width={baseWidth} height={baseHeight}>
        <div className="login-modal">
          <div className="side-image" style={imageSize} />
          <SvgLoader width={24} height={24} svgName="cross-login" onClick={() => setShowConfirmationModal(false)} pointer />
          <div className="login-header">
            <div className="login-form">
              <div className="welcome-text mt-32">
                Confirm Your Email
              </div>
            </div>
            <div className="mt-24 sub-header p-2">
              <span>
                Please confirm that
                &nbsp;
                <strong>{email}</strong>
                &nbsp;
                is correct.
              </span>
            </div>
            <div className="flex gap-4">
              <button className="no-button-capture-email pointer" type="submit">No</button>
              <button className="yes-button-capture-email pointer" type="submit">Yes</button>
            </div>
          </div>
        </div>
      </Modal>
    );
  }

  return null;
}
