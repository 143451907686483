import React from 'react';
import DatePicker from 'react-datepicker';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import SvgLoader from '../../../common/components/SvgLoader';
import 'react-datepicker/dist/react-datepicker.css';
import TimeInput from '../../components/TimeInput';
import { filterUpdateFromReduxAction, updateAdminFiltersAction } from '../FilterAdmin/logics';
import { updateInviteUserParamsAction } from '../InviteUserModal/logic';

export default function DateAndTimeRange(props) {
  const dispatch = useDispatch();

  const {
    setOpen, title, startDate, endDate, startTime, endTime, setStartDate, setEndDate, setStartTime, setEndTime, filterAdmin = false, handleSubmit = () => {}, inviteUser = false, setFormValue, formValue, orgUpdate = false,
  } = props;

  const convertDateFormat = (dateString) => {
    const date = moment(dateString);

    if (date.isValid()) {
      return date.format('DD MMM, YYYY');
    }
    return '';
  };

  const convertToTimestamp = (dateString) => {
    const normalizedString = dateString.replace(/(AM|PM)/i, (match) => match.toUpperCase());

    const date = moment(normalizedString);

    if (date.isValid()) {
      return date.valueOf();
    }
    return 0;
  };

  const handleClose = () => {
    setOpen(false);
    if (filterAdmin) {
      dispatch(filterUpdateFromReduxAction());
    }
  };

  const handleDoneclick = () => {
    handleSubmit();
    if (filterAdmin) {
      const startTimeStamp = convertToTimestamp(`${startDate} ${startTime}`);
      const endTimeStamp = convertToTimestamp(`${endDate} ${endTime}`);
      dispatch(updateAdminFiltersAction({
        key: 'license_date',
        options: [startTimeStamp, endTimeStamp],
      }));
      setOpen(false);
      dispatch(filterUpdateFromReduxAction());
    }

    if (inviteUser) {
      const startTimeStamp = convertToTimestamp(`${startDate} ${startTime}`);
      const endTimeStamp = convertToTimestamp(`${endDate} ${endTime}`);
      dispatch(updateInviteUserParamsAction({
        key: 'license_date',
        options: [startTimeStamp, endTimeStamp],
      }));
      setOpen(false);
      dispatch(filterUpdateFromReduxAction());
    }
    if (orgUpdate) {
      const startTimeStamp = convertToTimestamp(`${startDate} ${startTime}`);
      const endTimeStamp = convertToTimestamp(`${endDate} ${endTime}`);
      // dispatch(updateAdminFiltersAction({
      //   key: 'license_date',
      //   options: [startTimeStamp, endTimeStamp],
      // }));
      setFormValue({ ...formValue, licenseDate: [startTimeStamp, endTimeStamp] });
      setOpen(false);
      // dispatch(filterUpdateFromReduxAction());
    }
  };

  const handleDateUpdate = (type, date) => {
    if (type === 'left') {
      setStartDate(convertDateFormat(date));
    } else {
      setEndDate(convertDateFormat(date));
    }
  };

  const renderActivities = (type) => (
    <div className={`${type === 'left' ? 'left-side' : 'right-side'}`}>
      {type === 'left'
        ? (
          <DatePicker
            selected={type === 'left' ? startDate : endDate}
            onChange={(date) => handleDateUpdate(type, date)}
            open
            inline
          />
        )
        : (
          <DatePicker
            selected={type === 'left' ? startDate : endDate}
            onChange={(date) => handleDateUpdate(type, date)}
            minDate={startDate}
            open
            inline
          />
        )}
      <TimeInput
        title={type === 'left' ? 'Start Time' : 'End Time'}
        time={type === 'left' ? startTime : endTime}
        updateTime={type === 'left' ? setStartTime : setEndTime}
      />
    </div>
  );

  return (
    <div>
      <div className="filter-header">
        <div>{title}</div>
        <SvgLoader onClick={() => handleClose()} pointer width={14.7} height={14.7} svgName="modal-cross" hoverIconName="modal-cross-hover" />
      </div>
      <div className="date-time-container">
        <div className="display-container">
          <div className="date-display adjust-mr-date">
            <div className="lable-text">Start Date</div>
            <div className="date-text">
              {startDate}
              <div className="dash" />
              {startTime}
              {' '}
            </div>
          </div>
          <div className="date-display">
            <div className="lable-text">End Date</div>
            <div className="date-text">
              {endDate}
              <div className="dash" />
              {endTime}
            </div>
          </div>
        </div>
        <div className="activity-container">
          {renderActivities('left')}
          <div className="section-divider" />
          {renderActivities('right')}
        </div>
        <div className="btn-compnent">
          <div aria-hidden className="btn-cancel" onClick={() => handleClose()}>Cancel</div>
          <div aria-hidden className="btn-done" onClick={() => handleDoneclick()}>Done</div>
        </div>
      </div>
    </div>
  );
}
