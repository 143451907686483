import {
  Button, Form, Input, Modal, Radio, Checkbox,
} from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import queryString from 'query-string';
import AdminDropdown from '../AdminDropdown';
import {
  createOrganizationAction,
  createOrganizationRefreshAction,
  editOrganizationAction,
  editOrganizationRefreshAction,
  getOrganizationValuesAction,
  getOrganizationValuesRefreshAction,
} from '../Admin/logic';
import SvgLoader from '../../../common/components/SvgLoader';
import { getCookie, setCookie, getCurrentDateTime } from '../../../utils';
import AdminDateAndTimeInput from '../AdminDateAndTimeInput';
import DateAndTimeRange from '../DateAndTimeRange';
import AdminFilterMultiSelect from '../AdminFilterMultiSelect';

export default function UpdateOrg({
  open,
  setOpen,
  orgData = {},
  type = '',
  loadData = () => {},
  search,
}) {
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const currentDate = getCurrentDateTime().date;
  const currentTime = getCurrentDateTime().time;

  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [isFailureModalOpen, setIsFailureModalOpen] = useState(false);
  const [showDateTimePickerModal, setShowDatePickerModal] = useState(false);
  const [startDate, setStartDate] = useState(currentDate);
  const [endDate, setEndDate] = useState(currentDate);
  const [startTime, setStartTime] = useState(currentTime);
  const [endTime, setEndTime] = useState(currentTime);
  // eslint-disable-next-line no-unused-vars
  const [note, setNote] = useState('');
  const [notify, setNotify] = useState(false);
  const [licenseOption, setLicenseOption] = useState('period'); // 'period' or 'date'
  const [selectedTerminals, setSelectedTerminals] = useState('');
  const [terminalTags, setTerminalTags] = useState([]);
  const [formValue, setFormValue] = useState({
    organizationId: '',
    firstName: '',
    lastName: '',
    email: '',
    organization: '',
    organizationName: '',
    job: '',
    organizationType: 'external',
    domain: '',
    licensePeriod: 15.0,
    notifyEmail: false,
    licenseDate: '',
    terminals: [],
  });

  const createOrganization = useSelector((state) => state.createOrganization);
  const editOrganization = useSelector((state) => state.editOrganization);
  const dateTimeModalTitle = 'Licence period';

  const defaultSTate = () => {
    setStartDate(currentDate);
    setEndDate(currentDate);
    setStartTime(currentTime);
    setEndTime(currentTime);
    setLicenseOption('period');
    setOpen(false);
    setFormValue({
      ...{
        organizationId: '',
        firstName: '',
        lastName: '',
        email: '',
        organization: '',
        organizationName: '',
        job: '',
        organizationType: 'external',
        domain: '',
        licensePeriod: 15.0,
        notifyEmail: false,
        licenseDate: '',
        terminals: [],
      },
    });
    form.resetFields();
  };

  const terminalNames = {
    label: 'List of terminals/features',
    type: 'multiselect',
    options: [
      { label: 'Clinical Trials', value: 'clinical_trials' },
      { label: 'Search And Explore', value: 'search_and_explore' },
      { label: 'Intervention', value: 'intervention' },
      { label: 'Key Opinion Leaders', value: 'influence' },
      { label: 'Bio-network', value: 'insight' },
    ],
    key: 'terminals',
  };

  const formatTime = (timestamp) => {
    if (timestamp) {
      const momentObj = moment(Number(timestamp));
      const formattedTime = momentObj.format('hh:mm A');
      return formattedTime;
    }
    return null;
  };

  const getDateTimeFromTimeStamp = (timestamp) => {
    const date = new Date(timestamp);

    const monthNames = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'June',
      'July',
      'Aug',
      'Sept',
      'Oct',
      'Nov',
      'Dec',
    ];

    const year = date.getUTCFullYear();
    const month = monthNames[date.getMonth()];
    const day = String(date.getDate()).padStart(2, '0');

    let hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');

    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours %= 12;
    hours = hours ? String(hours).padStart(2, '0') : '12';

    const getDate = `${day} ${month}, ${year}`;
    const getTime = `${hours}:${minutes} ${ampm}`;
    return {
      date: getDate,
      time: getTime,
    };
  };

  useEffect(() => {
    if (editOrganization.flag && getCookie('orgUpdateType') === type) {
      dispatch(editOrganizationRefreshAction());
      dispatch(getOrganizationValuesRefreshAction());
      loadData();
      setIsConfirmationModalOpen(true);
      defaultSTate();
    }

    if (
      editOrganization?.data?.error?.statusCode === 409
      && getCookie('orgUpdateType') === type
    ) {
      dispatch(editOrganizationRefreshAction());
      setIsFailureModalOpen(true);
    }
  }, [JSON.stringify(editOrganization)]);

  useEffect(() => {
    if (
      type === 'edit'
      && orgData?.license_start_date
      && orgData?.license_end_date
    ) {
      const startTimeStamp = orgData?.license_start_date;
      const endTimeStamp = orgData?.license_end_date;
      setStartTime(formatTime(startTimeStamp));
      setEndTime(formatTime(endTimeStamp));
    }
  }, [orgData]);

  useEffect(() => {
    if (type === 'edit') {
      if (orgData?.license_start_date) {
        const { date } = getDateTimeFromTimeStamp(orgData?.license_start_date);
        setStartDate(date);
      }
      if (orgData?.license_end_date) {
        const { date } = getDateTimeFromTimeStamp(orgData?.license_end_date);
        setEndDate(date);
      }
    }
  }, [orgData]);
  useEffect(() => {
    if (createOrganization.flag && getCookie('orgUpdateType') === type) {
      dispatch(createOrganizationRefreshAction());
      dispatch(getOrganizationValuesRefreshAction());
      loadData();
      setIsConfirmationModalOpen(true);
      defaultSTate();
    } else if (
      createOrganization?.data?.statusCode === 409
      && getCookie('orgUpdateType') === type
    ) {
      dispatch(createOrganizationRefreshAction());
      setIsFailureModalOpen(true);
      defaultSTate();
    }
  }, [JSON.stringify(createOrganization)]);

  useEffect(() => {
    if (JSON.stringify(orgData) !== '{}') {
      setFormValue({
        ...formValue,
        organizationName: orgData.organization_name,
        organizationType: orgData.organization_type,
        domain: orgData.domain,
        organizationId: orgData.organization_id,
        licensePeriod: orgData.period,
        terminals: orgData.terminals,
      });
      setOpen(true);
      setCookie('edit', 'orgUpdateType');
    }
  }, [JSON.stringify(orgData)]);

  useEffect(() => {
    if (orgData?.license_start_date) {
      setLicenseOption('date');
    } else if (orgData?.period) {
      setLicenseOption('period');
    }
  }, [JSON.stringify(orgData)]);
  const formValueChange = (e, keyName) => {
    if (keyName === 'firstName') {
      setFormValue({
        ...formValue,
        firstName: e.target.value,
      });
    }
    if (keyName === 'lastName') {
      setFormValue({
        ...formValue,
        lastName: e.target.value,
      });
    }
    if (keyName === 'email') {
      setFormValue({
        ...formValue,
        email: e.target.value,
      });
    }
    if (keyName === 'job') {
      setFormValue({
        ...formValue,
        job: e.target.value,
      });
    }
    if (keyName === 'organizationName') {
      setFormValue({
        ...formValue,
        organizationName: e.target.value,
      });
    }
    if (keyName === 'organizationType') {
      setFormValue({
        ...formValue,
        organizationType: e.target.value,
      });
    }
    if (keyName === 'domain') {
      setFormValue({
        ...formValue,
        domain: e.target.value,
      });
    }
    if (keyName === 'licensePeriod') {
      setFormValue({ ...formValue, licensePeriod: e.target.value });
    }
  };

  const handleTerminalSelectionChange = (selectedKeys) => {
    setSelectedTerminals(selectedKeys);
  };

  const handleOrgTypeUpdate = (value) => {
    setFormValue({ ...formValue, organizationType: value });
  };

  const handlePrefilled = () => {
    if (formValue.organizationType === 'internal') {
      return { key: 'internal', label: 'Internal' };
    }
    return { key: 'external', label: 'External' };
  };

  const options = [
    { key: 'external', label: 'External' },
    { key: 'internal', label: 'Internal' },
  ];

  const convertToTimestamp = (dateString) => {
    // Replace AM/PM with lowercase to handle it correctly
    const normalizedString = dateString.replace(/(AM|PM)/i, (match) => match.toUpperCase());

    // Create a new Date object from the normalized date string
    const date = new Date(normalizedString);

    // Return the timestamp (milliseconds since Unix epoch)
    return date.getTime();
  };
  const handleUpdateClick = () => {
    const startTimeStamp = convertToTimestamp(`${startDate} ${startTime}`);
    const endTimeStamp = convertToTimestamp(`${endDate} ${endTime}`);
    defaultSTate();
    const actionBody = licenseOption === 'period'
      ? {
        organization_name: formValue.organizationName,
        organization_type: formValue.organizationType,
        domain: formValue.domain,
        period: parseFloat(formValue.licensePeriod) || 15.0,
        terminals: selectedTerminals,
        license_start_date: 0,
        license_end_date: 0,
      }
      : licenseOption === 'date'
        ? {
          organization_name: formValue.organizationName,
          organization_type: formValue.organizationType,
          domain: formValue.domain,
          license_start_date: startTimeStamp,
          license_end_date: endTimeStamp,
          terminals: selectedTerminals,
          period: 0,
        }
        : {
          organization_name: formValue.organizationName,
          organization_type: formValue.organizationType,
          domain: formValue.domain,
          terminals: '',
          enterprise_only: true,
          license_start_date: 0,
          license_end_date: 0,
          period: 0,
        };

    const request = {
      headers: queryString.stringify({
        organization_id: formValue.organizationId,
        existing_domain: orgData.domain,
        notify_users: formValue.notifyEmail,
        // enterprise_only: true,
        // license_start_date: 0,
        // license_end_date: 0,
      }),
      body: actionBody,
    };
    dispatch(editOrganizationAction(request));
  };

  const handleClick = () => {
    const startTimeStamp = convertToTimestamp(`${startDate} ${startTime}`);
    const endTimeStamp = convertToTimestamp(`${endDate} ${endTime}`);
    if (type === 'edit') {
      handleUpdateClick();
    } else {
      const actionBody = licenseOption === 'period'
        ? {
          organization_name: formValue.organizationName,
          organization_type: formValue.organizationType,
          domain: formValue.domain,
          period: parseFloat(formValue.licensePeriod) || 15.0,
          terminals: selectedTerminals,
        }
        : licenseOption === 'date'
          ? {
            organization_name: formValue.organizationName,
            organization_type: formValue.organizationType,
            domain: formValue.domain,
            license_start_date: startTimeStamp,
            license_end_date: endTimeStamp,
            terminals: selectedTerminals,
          }
          : {
            organization_name: formValue.organizationName,
            organization_type: formValue.organizationType,
            domain: formValue.domain,
            enterprise_only: true,
          };

      dispatch(createOrganizationAction({ body: actionBody }));
      defaultSTate();
    }
  };

  const handleCancel = () => {
    setIsConfirmationModalOpen(false);
    dispatch(getOrganizationValuesRefreshAction());
    if (type === 'create') {
      const request = {
        from: 1,
        size: 100,
        search,
      };
      dispatch(getOrganizationValuesAction(queryString.stringify(request)));
    } else {
      loadData();
    }
    setIsFailureModalOpen(false);
    setCookie('', 'orgUpdateType');
    defaultSTate();
  };

  if (
    getCookie('orgUpdateType') !== ''
    && getCookie('orgUpdateType') !== type
  ) {
    return null;
  }

  const modalCancelClick = () => {
    setCookie('', 'orgUpdateType');
    dispatch(getOrganizationValuesRefreshAction());
    if (type === 'create') {
      const request = {
        from: 1,
        size: 100,
        search,
      };
      dispatch(getOrganizationValuesAction(queryString.stringify(request)));
    } else {
      loadData();
    }
    setTerminalTags([]);
    defaultSTate();
  };

  const orgUpdateSuccessfull = () => (
    <Modal
      open={isConfirmationModalOpen}
      onCancel={handleCancel}
      okButtonProps={{ style: { display: 'none' } }}
      className="admin-invite-user-success-modal"
      cancelButtonProps={{ style: { display: 'none' } }}
    >
      <div className="invite-success-icon">
        <SvgLoader
          svgName="success-signup"
          width={120}
          height={120}
          style={{ display: 'flex' }}
        />
      </div>
      <div className="invite-success-msg">
        {getCookie('orgUpdateType') === 'edit'
          ? 'Organization updated successfully'
          : 'Organization created successfully'}
      </div>
    </Modal>
  );

  const handleLicenseChange = (e) => {
    setLicenseOption(e.target.value);
    if (e.target.value === 'date' && type === 'edit') {
      setNotify(true);
      setNote(
        ' Caution: Converting to a dates based license will permanently delete your period-based license settings',
      );
    } else if (e.target.value === 'period' && type === 'edit') {
      setNotify(true);
      setNote(
        'Caution: Converting to a period-based license will permanently delete your current start and end dates',
      );
    } else {
      setNotify(false);
      setNote('');
    }
  };

  const renderLicenseOptions = () => (
    <Form.Item label="Select company-wide license option">
      <Radio.Group
        value={licenseOption}
        onChange={(e) => handleLicenseChange(e)}
      >
        <Radio value="period">License period (in days)</Radio>
        <Radio value="date">License start and end date</Radio>
        <Radio value="enterprise">Enterprise only</Radio>
      </Radio.Group>
    </Form.Item>
  );

  const renderOrgFailure = () => (
    <Modal
      open={isFailureModalOpen}
      onCancel={handleCancel}
      okButtonProps={{ style: { display: 'none' } }}
      className="admin-invite-user-success-modal"
      cancelButtonProps={{ style: { display: 'none' } }}
    >
      <div className="invite-success-icon">
        <SvgLoader
          svgName="failure"
          width={120}
          height={120}
          style={{ display: 'flex' }}
        />
      </div>
      <div className="invite-success-msg">Organization already exists</div>
    </Modal>
  );

  return (
    <div>
      <Modal
        title={
          showDateTimePickerModal
            ? null
            : `${
              type === 'edit' ? 'Update organization' : 'Create organization'
            }`
        }
        style={{
          height: '100vh',
        }}
        open={open}
        onCancel={() => modalCancelClick()}
        okButtonProps={{ style: { display: 'none' } }}
        cancelButtonProps={{ style: { display: 'none' } }}
        className="admin-invite-user-modal org-modal"
        closable={!showDateTimePickerModal}
      >
        {showDateTimePickerModal ? (
          <DateAndTimeRange
            open={showDateTimePickerModal}
            setOpen={setShowDatePickerModal}
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
            startTime={startTime}
            setStartTime={setStartTime}
            endTime={endTime}
            setEndTime={setEndTime}
            title={dateTimeModalTitle}
            setFormValue={setFormValue}
            formValue={formValue}
            orgUpdate
          />
        ) : (
          <div className="invite-user">
            <Form form={form} name="invite-user-form" layout="vertical">
              <Form.Item
                label="Organization name"
                name="organizationName"
                rules={[
                  {
                    required: true,
                    message: 'Please input the organization name!',
                  },
                ]}
              >
                {type === 'create' ? null : (
                  <h1 style={{ visibility: 'hidden', display: 'none' }}>
                    {formValue.organization}
                  </h1>
                )}

                <Input
                  className="input-wrapper"
                  placeholder="Organization name"
                  value={formValue.organizationName}
                  onChange={(e) => formValueChange(e, 'organizationName')}
                />
              </Form.Item>

              <Form.Item
                label="Organization Type"
                name="organizationType"
                rules={[
                  {
                    required: true,
                    message: 'Please select the organization type!',
                  },
                ]}
              >
                <AdminDropdown
                  prefilled={() => handlePrefilled()}
                  options={options}
                  handleOrgTypeUpdate={handleOrgTypeUpdate}
                />
              </Form.Item>

              <Form.Item
                label="Domain"
                name="domain"
                rules={[
                  {
                    required: true,
                    message: 'Please input domain name',
                  },
                ]}
              >
                {type === 'edit' ? <h1 style={{ visibility: 'hidden', display: 'none' }}>{formValue.domain}</h1> : null}
                <Input
                  className="input-wrapper"
                  placeholder="Domain"
                  value={formValue.domain}
                  onChange={(e) => formValueChange(e, 'domain')}
                />
              </Form.Item>
              {licenseOption !== 'enterprise' ? (
                <Form.Item label="List of features/Terminals" name="listOfTerminals">

                  <div className="filter-container-admin filter-container-admin-org-terminal">
                    <AdminFilterMultiSelect
                      filter={terminalNames}
                      onSelectionChange={handleTerminalSelectionChange}
                      setFormValue={setFormValue}
                      formValue={formValue}
                      updateOrg={type}
                      orgData={orgData}
                      isModalOpen={open}
                      terminalTags={terminalTags}
                    />
                  </div>
                </Form.Item>

              ) : (
                <Form.Item label="" name="">

                  <div style={{ height: '50px' }} />
                  {' '}
                </Form.Item>

              )}

              {renderLicenseOptions()}
              {licenseOption === 'period' ? (
                <Form.Item
                  label="License period (in days)"
                  name="licensePeriod"
                  rules={[
                    { required: true, message: 'Please input license period greater than 0' },
                  ]}
                >
                  <h1 style={{ visibility: 'hidden', display: 'none' }}>{formValue.licensePeriod || 15.0}</h1>
                  <Input
                    value={formValue.licensePeriod}
                    step="any"
                    className="input-wrapper"
                    placeholder="License period"
                    rules={[
                      {
                        required: true,
                        message: 'Please enter license period greater than 0',
                      },
                    ]}
                    onChange={(e) => {
                      formValueChange(e, 'licensePeriod');
                      if (type === 'edit') { setNotify(true); }
                    }}
                    onKeyDown={(e) => {
                      const validKeys = [
                        'Backspace',
                        'Delete',
                        'Tab',
                        'Escape',
                        'Enter',
                      ];
                      if (validKeys.includes(e.key)) {
                        return;
                      }
                      if (!/[0-9.]/.test(e.key)) {
                        e.preventDefault(); // Prevent default action for invalid keys
                      }
                    }}
                  />
                </Form.Item>
              ) : licenseOption === 'date' ? (
                <Form.Item
                  label="License start and end date"
                  rules={[
                    {
                      required: true,
                      message: 'Please select license start and end date',
                    },
                  ]}
                >
                  <div className="filter-container-admin org">
                    <div className="filter-sub-container">
                      <div className="filter-name" />
                      <div className="sub-list">
                        <AdminDateAndTimeInput
                          dateAndtimeObj={{
                            endDate,
                            startDate,
                            endTime,
                            startTime,
                            showDateTimePickerModal,
                            setShowDatePickerModal,
                            setNotify,
                            type,
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </Form.Item>
              ) : null}

              {notify === true ? (
                <Form.Item name="termsCheckbox" valuePropName="checked">
                  <Checkbox
                    onChange={(e) => setFormValue({
                      ...formValue,
                      notifyEmail: e.target.checked,
                    })}
                  >
                    Notify
                  </Checkbox>
                </Form.Item>
              ) : null}

              <div className="license-note" style={{ color: 'red' }}>
                {note}
              </div>

              <Form.Item>
                <Button
                  type="default"
                  htmlType="button"
                  disabled={
                    formValue.organizationName === ''
                    || formValue.organizationType === ''
                    || formValue.domain === ''
                  }
                  onClick={handleClick}
                >
                  Submit
                </Button>
              </Form.Item>
            </Form>
          </div>
        )}
      </Modal>
      {isConfirmationModalOpen ? orgUpdateSuccessfull() : null}
      {isFailureModalOpen ? renderOrgFailure() : null}
    </div>
  );
}
