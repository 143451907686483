import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateInviteUserParamsAction } from '../InviteUserModal/logic';

const removeDuplicates = (arr) => {
  const uniqueSet = new Set(arr);
  return [...uniqueSet];
};

const hasDuplicates = (arr) => {
  const uniqueSet = new Set(arr);
  return arr.length !== uniqueSet.size;
};

export default function EmailInputBox({ type = '', update, reset }) {
  const dispatch = useDispatch();

  const inviteUserStatus = useSelector((state) => state.inviteUserStatus);

  const [email, setEmail] = useState('');
  const [emailList, setEmailList] = useState([...inviteUserStatus.emails] || []);
  const [invalidEmails, setInvalidEmails] = useState([]);

  const showLoader = false;

  const processEmail = (emailProcessed) => {
    const plusIndex = emailProcessed.indexOf('+');
    if (plusIndex !== -1) {
      return emailProcessed.substring(0, plusIndex) + emailProcessed.substring(emailProcessed.indexOf('@'));
    }
    return emailProcessed;
  };

  const checkDomain = (user) => {
    if (type === 'invite-user') {
      if (inviteUserStatus.organization_name.domain.length) {
        return user.includes(inviteUserStatus.organization_name.domain);
      }
    }
    return true;
  };

  useEffect(() => {
    if (reset) {
      setEmailList([]);
    }
  }, [reset]);

  useEffect(() => {
    if (update) {
      setEmailList([...inviteUserStatus.emails]);
    }
  }, [update]);

  useEffect(() => {
    if (hasDuplicates(emailList)) {
      const uniqueList = removeDuplicates(emailList).filter((item) => item !== '');
      dispatch(updateInviteUserParamsAction({
        key: 'emails',
        options: uniqueList.filter((item) => !invalidEmails.includes(item)),
      }));
      setEmailList([...uniqueList.map((item) => processEmail(item))]);
    } else {
      const filteredList = emailList.filter((item) => checkDomain(item));
      dispatch(updateInviteUserParamsAction({
        key: 'emails',
        options: filteredList.filter((item) => !invalidEmails.includes(item)),
      }));
    }
  }, [JSON.stringify(emailList)]);

  const check = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  const extractEmail = (emailInput) => {
    const emailPattern = /(?:<([^>]+)>|([a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2.}))/;
    const match = emailInput.match(emailPattern);

    if (match) {
      return match[1].trim().toLowerCase() || match[2].trim().toLowerCase();
    }
    if (check.test(emailInput)) {
      return emailInput;
    }
    return null;
  };

  const handleInputChange = (event) => {
    setEmail(event.target.value);
  };

  useEffect(() => {
    const tempInvalidEmails = invalidEmails.filter((item) => !emailList.includes(item));
    setEmailList([...emailList, ...tempInvalidEmails.map((item) => processEmail(item))]);
  }, [JSON.stringify(invalidEmails)]);

  const processB = (emails) => {
    let j = 0;
    let i = 0;
    const tempEmails = [];
    const tempInvalidEmails = [];
    while (i < emails.length) {
      const ch = emails[i];
      if (ch === ' ') {
        const tempEmail = extractEmail(emails.slice(j, i));
        if (tempEmail) {
          tempEmails.push(tempEmail);
          j = i + 1;
        }
      } else if (i === emails.length - 1) {
        const tempEmail = extractEmail(emails.slice(j, i + 1));
        if (tempEmail) {
          tempEmails.push(tempEmail);
          break;
        } else if (emails.slice(j, i + 1).includes(' ')) {
          const tempEmail1 = emails.slice(j, i + 1);
          while (j < emails.length) {
            if (emails[j] === ' ') {
              j += 1;
              i -= 1;
              tempInvalidEmails.push(tempEmail1.split(' ')[0]);
              break;
            }
            j += 1;
          }
        } else {
          const tempEmail1 = emails.slice(j, i + 1);
          tempInvalidEmails.push(tempEmail1.split(' ')[0]);
        }
      }
      i += 1;
    }
    if (tempInvalidEmails.length) {
      setInvalidEmails([...invalidEmails, ...tempInvalidEmails]);
    }
    return tempEmails;
  };

  const processA = (emails) => {
    const allEmails = emails.split(',');
    const tempEmails = [];
    allEmails.forEach((item) => {
      if (processB(item) && processB(item).length) {
        tempEmails.push(...processB(item));
      }
    });
    return JSON.parse(JSON.stringify(tempEmails));
  };

  const ctrAllProcess = () => {
    if (email.includes(',')) {
      let tempEmails = [];

      const emailFromProcessA = processA(email);
      tempEmails = emailFromProcessA.map((item) => processEmail(item));
      if (tempEmails.length >= 1) {
        setEmailList([...emailList, ...tempEmails]);
        setEmail('');
      }
    } else if (email.includes(' ')) {
      let tempEmails = [];

      const emailsFromProcessB = processB(email);
      tempEmails = emailsFromProcessB.map((item) => processEmail(item));

      if (tempEmails.length >= 1) {
        setEmailList([...emailList, ...tempEmails]);
        setEmail('');
      }
    } else if (extractEmail(email)) {
      setEmailList([...emailList, processEmail(extractEmail(email))]);
      setEmail('');
    }
  };

  const handleEnterKey = (event) => {
    if (event.key === 'Enter') {
      ctrAllProcess();
    }
  };

  const handleCrossIconClick = (user) => {
    const filterList = emailList.filter((value) => value !== user);
    const filterInvalidList = invalidEmails.filter((value) => value !== user);
    setInvalidEmails([...filterInvalidList]);
    setEmailList([...filterList]);
  };

  const renderUsersTag = () => emailList.map((user) => (
    <div className={`py-1.5 px-2.5 ${check.test(user) && checkDomain(user) ? 'user-tag' : 'user-tag-red'} flex`} key={user}>
      <span>{user}</span>
      <div aria-hidden onClick={() => handleCrossIconClick(user)} className={check.test(user) && checkDomain(user) ? 'croos-btn-tag-user' : 'cross-red'} />
    </div>
  ));

  return (
    <div>
      <div className="email-input-container rounded" onMouseLeave={() => ctrAllProcess()}>
        <div className="flex flex-wrap">
          {showLoader
            ? (
              <div className="relative left-4 top-2.5 h-6">
                <div className="snippet" data-title="dot-flashing">
                  <div className="stage">
                    <div className="dot-flashing" />
                  </div>
                </div>
              </div>
            ) : renderUsersTag()}
          <input className="input-email m-1 px-2.5 w-full" placeholder="Email ID" type="text" value={email} onChange={(e) => handleInputChange(e)} onKeyDown={handleEnterKey} />
        </div>
      </div>
      {
          invalidEmails.length !== 0 ? (
            <div className="not-valid">
              <span>
                Entered email id(s) are not valid
              </span>
            </div>
          ) : null
        }
    </div>
  );
}
