import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useSearchParams, useLocation } from 'react-router-dom';
import { getTerimalPage, trackOutSideEvents } from '../../../utils';
import Mixpanel from '../../../utils/mixpanel';

function MultiSelectDropdown({
  open, setOpen, options, currentOptions, setCurrentOptions, feature,
}) {
  const location = useLocation();
  const multiSelectDropdownRef = useRef(null);
  const [searchParams] = useSearchParams();
  const query = searchParams.get('query');
  const semanticQuery = searchParams.get('semanticquery');

  useEffect(() => {
    trackOutSideEvents(multiSelectDropdownRef, () => setOpen(false));
  }, []);

  if (!open) {
    return null;
  }

  const handleOptionClick = (value) => {
    if (currentOptions.indexOf(value[0]) > -1) {
      setCurrentOptions([]);
      if (feature === 'Phase graph') {
        Mixpanel.track('button_click_event', {
          action: 'trend line click',
          page_name: getTerimalPage(location),
          terminal_name: 'Clinical trials',
          widge_name: 'Trial phase breakdown',
          view: `CT Analytics page with ${semanticQuery ? 'semantic search query' : 'power search query'}`,
          filter_value: JSON.parse(semanticQuery || query),
        });
      }
    } else {
      setCurrentOptions([...value]);
      Mixpanel.track('button_click_event', {
        action: 'trend line click',
        page_name: getTerimalPage(location),
        terminal_name: 'Clinical trials',
        widge_name: 'Trial phase breakdown',
        view: `CT Analytics page with ${semanticQuery ? 'semantic search query' : 'power search query'}`,
        filter_value: JSON.parse(semanticQuery || query),
        misc: value,
      });
    }
  };

  const renderOptions = () => options.map((option) => (
    <div key={option.value} aria-hidden onClick={() => handleOptionClick(option.value)} className={`option ${currentOptions.includes(option.value[0]) ? 'active' : ''}`}>
      <input checked={currentOptions.includes(option.value[0])} className="option-checkbox" type="checkbox" />
      <span className="option-text">{option.name}</span>
    </div>
  ));

  return (
    <div className="multiselecte-dropdown style-for-md" ref={multiSelectDropdownRef}>
      <div className="option-container">
        {renderOptions()}
      </div>
    </div>
  );
}

MultiSelectDropdown.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  options: PropTypes.instanceOf(Array).isRequired,
  currentOptions: PropTypes.instanceOf(Array).isRequired,
  setCurrentOptions: PropTypes.func.isRequired,
  feature: PropTypes.func.isRequired,
};

export default MultiSelectDropdown;
