import { createAction, createReducer } from '@reduxjs/toolkit';
import {
  map, catchError, switchMap,
} from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';
import apiCall from '../../../utils';

const INITIAL_PARAMS = {
  organization_name: {
    domain: '',
    organization_id: '',
    enteredOrganization: '',
  },
  license_date: [],
  emails: [],
};

const UPDATE_INVITE_USER_PARAMS = 'UPDATE_INVITE_USER_PARAMS';
const REFRESH_USER_INVITE_PARAMS = 'REFRESH_USER_INVITE_PARAMS';

export const updateInviteUserParamsAction = createAction(UPDATE_INVITE_USER_PARAMS);
export const resetInviteUserParams = createAction(REFRESH_USER_INVITE_PARAMS);

const onUpdateAdminFilters = (state, payload) => {
  state[payload.key] = payload.options;
  return state;
};

const onResetAdminFilters = (state) => {
  state = { ...INITIAL_PARAMS };
  return state;
};

export const inviteUserReducer = createReducer(
  INITIAL_PARAMS,
  (builder) => {
    builder.addCase(updateInviteUserParamsAction, (state, action) => onUpdateAdminFilters(state, action.payload))
      .addCase(resetInviteUserParams, (state) => onResetAdminFilters(state))
      .addDefaultCase((state) => state);
  },
);

const INVITE_ENTERPRISE_USERS = 'INVITE_ENTERPRISE_USERS';
const INVITE_ENTERPRISE_USERS_SUCCESS = 'INVITE_ENTERPRISE_USERS_SUCCESS';
const INVITE_ENTERPRISE_USERS_FAILURE = 'INVITE_ENTERPRISE_USERS_FAILURE';
const INVITE_ENTERPRISE_USERS_REFRESH = 'INVITE_ENTERPRISE_USERS_REFRESH';

export const inviteEnterpriseUsers = createAction(INVITE_ENTERPRISE_USERS);

const inviteUserSuccess = createAction(INVITE_ENTERPRISE_USERS_SUCCESS);

const inviteUserFaliure = createAction(INVITE_ENTERPRISE_USERS_FAILURE);

export const inviteUserRefreshAction = createAction(INVITE_ENTERPRISE_USERS_REFRESH);

export const inviteUserEpic = (actions$) => actions$.pipe(
  ofType(INVITE_ENTERPRISE_USERS),
  switchMap((action) => ajax(apiCall(`${process.env.apiUrl}/common/v0/users/inviteEnterpriseUsers?${action.payload.headers}`, 'GET')).pipe(
    map((res) => inviteUserSuccess(res)),
    catchError((err) => of(inviteUserFaliure(err))),
  )),
);

const oninviteEnterpriseUsers = (state) => ({
  ...state,
  loading: true,
  error: false,
});

const oninviteUserSuccess = (state, { response }) => ({
  ...state,
  data: { ...response },
  loading: false,
  flag: true,
});

const oninviteUserFailure = (state) => ({
  ...state,
  flag: false,
  loading: false,
  showNotification: true,
  error: true,
});

const oninviteUserRefreshAction = () => ({
  data: {},
  loading: false,
  error: false,
  flag: false,
});

export const callingInviteUserReducer = createReducer(
  {
    data: {},
    loading: false,
    error: false,
    flag: false,
  },
  (builder) => {
    builder.addCase(inviteEnterpriseUsers, (state) => oninviteEnterpriseUsers(state))
      .addCase(inviteUserSuccess, (state, action) => oninviteUserSuccess(state, action.payload))
      .addCase(inviteUserFaliure, (state) => oninviteUserFailure(state))
      .addCase(inviteUserRefreshAction, () => oninviteUserRefreshAction())
      .addDefaultCase((state) => state);
  },
);
