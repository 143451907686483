import { createAction, createReducer } from '@reduxjs/toolkit';

const UPDATE_PREFILLED_EMAIL_DATA = 'UPDATE_PREFILLED_EMAIL_DATA';
const REFRESH_PREFILLED_EMAIL_DATA = 'REFRESH_PREFILLED_EMAIL_DATA';

export const updatePrefilledEmailDataAction = createAction(UPDATE_PREFILLED_EMAIL_DATA);
export const refreshPrefilledEmailDataAction = createAction(REFRESH_PREFILLED_EMAIL_DATA);

const INITIAL_DATA = {
  modalType: '',
  prefilledEmail: '',
  userType: '',
};

export const prefilledEmailReducer = createReducer(
  {
    ...INITIAL_DATA,
  },
  (builder) => {
    builder.addCase(updatePrefilledEmailDataAction, (state, action) => ({
      ...state,
      modalType: action.payload.modalType,
      prefilledEmail: action.payload.prefilledEmail,
      userType: action.payload.userType || '',
    }))
      .addCase(refreshPrefilledEmailDataAction, () => ({
        ...INITIAL_DATA,
      }))
      .addDefaultCase((state) => state);
  },
);
