/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';
import {
  Outlet, useLocation, useNavigate, useSearchParams,
} from 'react-router-dom';
import { Popover } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import queryString from 'query-string';
import moment from 'moment';
// import Clarity from '@microsoft/clarity';
import Status from '../../../common/container/Status';
import Notification from '../../../common/components/Notification';
import Footer from '../../../common/components/Footer';
import CookieSupport from '../../../common/components/CookieSupport';
import {
  // eslint-disable-next-line no-unused-vars
  eliminateCookies, getCookie, getTerimalPage, getTerminalName, setCookie, getTempSessionId,
} from '../../../utils';
import Loader from '../../../common/components/Loader';
import ChatBot from '../../../common/container/Chatbot';
import LoginModalV2 from '../LoginModalV2';
import { getLicenceAction } from './logic';
import TrialStatusModal from '../../components/TrialStatusModal';
import TrailPeriodExpiredModal from '../../components/TrailPeriodExpiredModal';
import RegectedModal from '../../components/RejectedModal';
import Mixpanel from '../../../utils/mixpanel';
import { closeChatbotAction, openChatbotAction } from '../../../common/container/Chatbot/logic';
import CheckPermissionTerminal from '../../components/CheckPermissionTerminal';
import FeedbackAndRefer from '../../components/FeedbackAndRefer';
import Feedback from '../FeedbackModal';
import Referral from '../Referral';
import { setFeedBackAndReferralModalOpenAction } from '../Referral/logic';
import CaptureEmail from '../../components/CaptureEmail';

function Main() {
  const [checkLandingPage, setCheckLandingPage] = useState(false);
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const query = searchParams.get('query');
  const semanticQuery = searchParams.get('semanticquery');
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const getLicence = useSelector((state) => state.getLicence);
  const open = useSelector((state) => state.isLoginModalOpen.open);
  const isChatbotOpen = useSelector((state) => state.chatbot.isOpen);

  function isEmailAllowed(email) {
    const notAllowedDomains = [
      'partex.io',
      'innoplexus.com',
      'amrit.ai',
      'perpetualblock.io',
      'cureteq.com',
      'assay.works',
    ];
    return !notAllowedDomains.some((domain) => email.includes(domain));
  }

  setTimeout(() => {
    if (getCookie('userEmail') && isEmailAllowed(getCookie('userEmail'))) {
      window.clarity('set', 'userId', `userId: ${getTempSessionId()},  userAccountType: ${getCookie('user_account_type')}`);
    }
  }, 0);
  useEffect(() => {
    const utm_campaign = searchParams.get('utm_campaign');
    if (utm_campaign) {
      const utm_content = searchParams.get('utm_content');
      const utm_source = searchParams.get('utm_source');
      setCookie(utm_campaign, 'utm_campaign');
      setCookie(utm_content, 'utm_content');
      setCookie(utm_source, 'utm_source');
    }
  }, []);

  useEffect(() => {
    if (getLicence.flag && JSON.stringify(getLicence.data) !== '{}') {
      setCookie(getLicence.data[0].license_end_date, 'expiresIN');
      const currentTimestamp = Date.now();
      if (Number(getLicence.data[0].license_end_date) <= currentTimestamp) {
        setCookie(true, 'feedbackReferModal');
        navigate(0);
      } else {
        setCookie(false, 'feedbackReferModal');
        navigate(0);
      }
    }

    if (getLicence.flag && JSON.stringify(getLicence.data) === '{}') {
      setCookie('indifinate', 'expiresIN');
    }
  }, [JSON.stringify(getLicence)]);

  const lightFooterAllowedPaths = ['/clinicalTrials/analyticsResults', '/clinicalTrials/searchResults', '/intervention/results', '/intervention/analytics', '/searchAndExplore/analytics', '/searchAndExplore/results', '/privacyPolicy', '/userPolicy', '/workspace', '/admin'];

  const classInPaths = ['/home', '/clinicalTrials', '/searchAndExplore', '/intervention'];

  useEffect(() => {
    if (getCookie('pageLoading') === 'true') {
      setTimeout(() => {
        setCookie(false, 'pageLoading');
        navigate(0);
      }, 4000);
    }
  }, []);

  useEffect(() => {
    const utm_source = searchParams.get('utm_source') || '';
    const utm_medium = searchParams.get('utm_medium') || '';
    const utm_campaign = searchParams.get('utm_campaign') || '';
    const utm_content = searchParams.get('utm_content') || '';
    const utm_term = searchParams.get('utm_term') || '';

    if (utm_source !== '') {
      const utmParams = {
        utm_source,
        utm_medium,
        utm_campaign,
        utm_content,
        utm_term,
      };

      setCookie(JSON.stringify(utmParams), 'utmParams');

      Mixpanel.track('utm_tracking_event', {
        action: 'utm tracking for tracking user source',
        page_name: 'Landing Page',
        utmParams,
      });
    }
  }, []);

  useEffect(() => {
    eliminateCookies();
  }, []);

  useEffect(() => {
    eliminateCookies();
  }, [document.cookie]);

  const renderBot = () => {
    Mixpanel.track('user_support', {
      action: 'ChatBot icon click',
      page: getTerimalPage(location),
      terminal_name: getTerminalName(location),
      support_type: 'ChatBot',
    });
    Mixpanel.track('button_click', {
      action: 'ChatBot button click',
      page_name: getTerimalPage(location),
      terminal_name: getTerminalName(location),
      misc: 'ChatBot',
      filter_value: JSON.parse(query) || JSON.parse(semanticQuery),
    });
    dispatch(openChatbotAction());
  };

  useEffect(() => {
    if (getCookie('accessToken') && !getCookie('expiresIN')) {
      dispatch(getLicenceAction({
        headers: queryString.stringify({
          email: getCookie('userEmail'),
        }),
      }));
    }
  }, []);

  const isWithinSevenDays = (timestamp) => {
    const currentTimestamp = moment();
    const providedTimestamp = moment(Number(timestamp));

    const differenceInDays = providedTimestamp.diff(currentTimestamp, 'days');
    return differenceInDays <= 7;
  };

  const checkTwoHrs = (timestamp) => {
    const currentTimestamp = moment();
    const providedTimestamp = moment(Number(timestamp));

    const diff = currentTimestamp.diff(providedTimestamp, 'hours');
    // const diff = currentTimestamp.diff(providedTimestamp, 'minutes');
    return diff >= 2;
  };

  useEffect(() => {
    if (getCookie('expiresIN') && getCookie('expirseIN') !== 'indifinate') {
      const currentTimestamp = Date.now();
      if (Number(getCookie('expiresIN')) <= currentTimestamp) {
        setCookie(true, 'feedbackReferModal');
      } else if (isWithinSevenDays(getCookie('expiresIN'))) {
        if (getCookie('previousTimeStamp') && checkTwoHrs(getCookie('previousTimeStamp'))) {
          dispatch(setFeedBackAndReferralModalOpenAction(true));
        } else if (!getCookie('previousTimeStamp')) {
          setCookie(currentTimestamp, 'previousTimeStamp');
        }
        setCookie(false, 'feedbackReferModal');
      } else {
        setCookie(false, 'feedbackReferModal');
      }
    }
  }, []);

  useEffect(() => {
    if (window.location.pathname === '/') {
      setCheckLandingPage(true);
    }
  }, [window.location.pathname]);

  const type = !lightFooterAllowedPaths.includes(location.pathname) || open ? 'dark' : 'light';

  const handleMainClassName = classInPaths.includes(location.pathname) ? 'new-bg-img app-container' : window.location.pathname === '/clinicalTrials/analyticsResults' || window.location.pathname === '/' ? 'app-container-analytics' : 'app-container';

  const notAllowedModalPaths = ['/', '/home', '/clinicalTrials', '/searchAndExplore', '/intervention', 'admin/v2'];

  return (
    <div className="app">
      <Loader error={false} loading={getCookie('pageLoading') === 'true' && window.location.pathname === '/'}>
        <div className={`${handleMainClassName}`}>
          {!location.pathname.includes(notAllowedModalPaths) ? <LoginModalV2 /> : null}
          <CaptureEmail />
          {!open ? <Outlet /> : null}
          {checkLandingPage
            && (
              <div className="sample-large full-height-no-header-footer login-page-main">
                <div className="login-page" />
              </div>
            )}
        </div>
        <Status />
        <Notification />
        <CookieSupport />
        {!getCookie('accessToken') || location.pathname.includes('/access') || location.pathname.includes('/contact') || location.pathname.includes('/privacyPolicy') || location.pathname.includes('/userPolicy') || location.pathname.includes('/admin') || location.pathname.includes('/redirect') ? (
          null
        ) : (
          <Popover
            overlayClassName="chatbot-popover"
            placement="topRight"
            trigger="click"
            open={isChatbotOpen}
            // eslint-disable-next-line no-shadow
            onOpenChange={(open) => {
              if (!open) {
                dispatch(closeChatbotAction());
              }
            }}
            content={<ChatBot />}
          >
            <div className={`chatbot-container footer-main${type === 'light' ? '-light' : ''} disp-flex vcenter`}>
              <div className="chatbot-icon" role="presentation" onClick={() => renderBot()} />
            </div>
          </Popover>
        )}
        <TrialStatusModal />
        <TrailPeriodExpiredModal />
        <CheckPermissionTerminal />
        <RegectedModal />
        <FeedbackAndRefer />
        <Feedback />
        <Referral />
        {location.pathname !== '/redirect' ? <Footer type={type} /> : null}
      </Loader>
    </div>
  );
}

export default Main;
