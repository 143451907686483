/* eslint-disable jsx-a11y/no-autofocus */
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import queryString from 'query-string';
import Panel from '../../../common/components/Panel';
import SvgLoader from '../../../common/components/SvgLoader';
import {
  debounceCall, modifyDataForDropdown, trackOutSideEvents, wordLimit,
} from '../../../utils';
import { getOrgUserLIstAction, getOrgUserLIstRefreshAction } from './logic';
import { updateAdminFiltersAction } from '../FilterAdmin/logics';
import { updateInviteUserParamsAction } from '../InviteUserModal/logic';
import Loader from '../../../common/components/Loader';

function AdminFilterAutoSuggest({
  className, filter, reset, type = '', selectedOrgId = {}, update = false,
}) {
  const multiSelectRef = useRef(null);
  const dispatch = useDispatch();

  const [input, setInput] = useState('');
  const [openDropdown, setOpendDropDown] = useState(false);
  const [tags, setTags] = useState([]);
  const [checkFocus, setCheckFocus] = useState(false);
  const [miniView, setMiniView] = useState(false);
  const [currentOPtions, setCurrentOptions] = useState([]);
  const [currentOrgId, setCurrentOrgId] = useState([]);

  const orgUserList = useSelector((state) => state?.orgUserList);
  const adminUserFilter = useSelector((state) => state?.adminUserFilter?.currentAdminFilter);
  const filtersUpdate = useSelector((state) => state?.filtersUpdate?.upate);
  const inviteUserStatus = useSelector((state) => state.inviteUserStatus);

  const options = modifyDataForDropdown(orgUserList.data.filter((item) => !item.is_deleted), 'domain', 'organization_id');

  useEffect(() => {
    if (type !== 'org-delete' && type !== 'invite-user') {
      const tempTags = adminUserFilter[filter.key];
      setTags([...tempTags]);
    }
  }, [filtersUpdate]);

  useEffect(() => {
    if (type === 'invite-user') {
      const tempTags = inviteUserStatus[filter.key].domain;
      const tempOrgId = inviteUserStatus[filter.key].organization_id;
      if (tempTags === '') {
        setCurrentOrgId([]);
        setTags([]);
      } else {
        setCurrentOrgId([...[tempOrgId]]);
        setTags([...[tempTags]]);
      }
      setInput(inviteUserStatus[filter.key].enteredOrganization);
    }
  }, [update]);

  useEffect(() => {
    if (type === 'org-delete') {
      selectedOrgId(currentOrgId);
    }
  }, [JSON.stringify(currentOrgId)]);

  useEffect(() => {
    if (type !== 'org-delete' && type !== 'invite-user') {
      dispatch(updateAdminFiltersAction({
        key: filter.key,
        options: tags,
      }));
    }

    if (type === 'invite-user' && tags.length !== 0) {
      dispatch(updateInviteUserParamsAction({
        key: filter.key,
        options:
          {
            domain: tags[0] || '',
            organization_id: currentOrgId[0] || '',
            enteredOrganization: '',
          },
      }));
    }
  }, [JSON.stringify(tags)]);

  useEffect(() => {
    if (reset) {
      setTags([]);
      setInput('');
    }
  }, [reset]);

  useEffect(() => {
    if (type === 'invite-user' && (tags.length === 0 && input === '') && !update) {
      dispatch(updateInviteUserParamsAction({
        key: filter.key,
        options: {
          domain: '',
          organization_id: '',
          enteredOrganization: input,
        },
      }));
    }
  }, [input, tags]);

  useEffect(() => {
    setCurrentOptions([...options]);
    if (type === 'invite-user' && orgUserList.flag && options.length === 0 && !update) {
      dispatch(updateInviteUserParamsAction({
        key: filter.key,
        options: {
          domain: '',
          organization_id: '',
          enteredOrganization: input,
        },
      }));
    }
  }, [JSON.stringify(options), JSON.stringify(orgUserList)]);

  useEffect(() => {
    trackOutSideEvents(multiSelectRef, () => {
      setMiniView(true);
      setOpendDropDown(false);
    });
  }, []);

  const updateOptions = (val) => {
    debounceCall(() => {
      dispatch(getOrgUserLIstRefreshAction());
      dispatch(getOrgUserLIstAction({
        headers: queryString.stringify({
          from: 1,
          search: val,
          size: 50,
        }),
      }));
    }, 200);
  };

  const handleChange = (event) => {
    setInput(event.target.value);
    updateOptions(event.target.value);
    event.preventDefault();
    setOpendDropDown(true);
  };

  const handleOptionClick = (value) => {
    if (type === 'org-delete' || type === 'invite-user') {
      if (tags.indexOf(value.label) !== -1) {
        setTags([]);
        setCurrentOrgId([]);
      } else {
        setTags([value.label]);
        setCurrentOrgId([value.organization_id]);
      }
    } else if (tags.indexOf(value.label) !== -1) {
      setTags([value.label]);
      setCurrentOrgId([value.organization_id]);
    } else {
      setTags([...tags, value.label]);
    }
    setInput('');
    setCheckFocus(true);
    setOpendDropDown(false);
  };

  const handleInputBoxClick = () => {
    if (options.length) {
      setOpendDropDown(true);
    }
  };

  const handleDivClick = () => {
    if (input === '') {
      debounceCall(() => {
        dispatch(getOrgUserLIstRefreshAction());
        dispatch(getOrgUserLIstAction({
          headers: queryString.stringify({
            from: 1,
            size: 50,
          }),
        }));
      }, 200);
    }
    setMiniView(false);
  };

  const handleCrossOptionClick = (i) => {
    const option = {
      label: tags[i],
    };

    handleOptionClick(option);
  };

  const renderTags = () => tags.map((item, i) => (
    <div className={`tags ${miniView ? '' : 'adjust-tag-size'}`} key={item}>
      <div className="tag-label" title={item}>
        {wordLimit(item, 20)}
      </div>
      <SvgLoader
        className="cross-icon"
        onClick={() => handleCrossOptionClick(i)}
        width={28}
        height={28}
        svgName="cross"
      />
    </div>
  ));

  const renderDropdowns = () => currentOPtions?.map((item) => (
    <div
      aria-hidden
      key={item.value}
      onClick={() => handleOptionClick(item)}
      className={`options ${className[2]} ${
        tags.indexOf(item.label) > -1 ? 'check' : ''
      }`}
    >
      {item.label}
      {tags.indexOf(item.label) > -1 && (
      <SvgLoader width={24} height={24} svgName="tick" />
      )}
    </div>
  ));

  const renderMiniView = () => (
    <>
      <div className="tags" title={tags[0]}>
        {wordLimit(tags[0], 20)}
        <SvgLoader
          className="cross-icon"
          onClick={() => handleCrossOptionClick(0)}
          width={28}
          height={28}
          svgName="cross"
        />
      </div>
      {tags.length - 1 ? (
        <div className="tags" style={{ width: 50 }}>
          {`+${
            tags.length - 1
          }`}
        </div>
      ) : null}
    </>
  );

  return (
    <div
      aria-hidden
      ref={multiSelectRef}
      className="multi-select-filter"
      onClick={() => handleDivClick()}
    >
      <div className="multi-select-wrap">
        {tags.length ? (
          <div className={`multi-select-div ${className[0]}`}>
            <div className="tags-data">
              {!miniView && renderTags()}
              {miniView ? renderMiniView() : null}
            </div>
          </div>
        ) : null}
        {(!miniView || !tags.length) && (
          <input
            autoFocus={checkFocus}
            className="multi-input"
            onClick={() => handleInputBoxClick()}
            onChange={handleChange}
            value={input}
          />
        )}
      </div>
      <div className="input-div">
        {openDropdown && (
          <Panel className={`panel ${className[1]}`}>
            <Loader
              loading={orgUserList.loading && !orgUserList.flag}
              error={orgUserList.error}
            >
              {renderDropdowns()}
              {orgUserList.flag && !orgUserList.loading && orgUserList.data.length === 0 ? (
                <div className="p-2 text-black">No Organization found</div>
              ) : null}
            </Loader>
          </Panel>
        )}
      </div>
    </div>
  );
}

export default AdminFilterAutoSuggest;
