import { Modal } from 'antd';
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import queryString from 'query-string';
import SvgLoader from '../../../common/components/SvgLoader';
import AdminFilterAutoSuggest from '../AdminFilterAutoSuggest';
import AdminDateAndTimeInput from '../AdminDateAndTimeInput';
import DateAndTimeRange from '../DateAndTimeRange';
import EmailInputBox from '../EmailInputBox';
import { appRoles, availableApps } from '../../../utils';
import { inviteEnterpriseUsers, inviteUserRefreshAction, resetInviteUserParams } from './logic';
import { sendNotification } from '../../../common/components/Notification/logic';

const getCurrentDateTime = () => {
  const now = moment();
  const formattedDate = now.format('DD MMM, YYYY');
  const formattedTime = now.format('hh:mm A');

  return {
    date: formattedDate,
    time: formattedTime,
  };
};

export default function InviteUserModal({ open, setOpen }) {
  const dispatch = useDispatch();
  const currentDate = getCurrentDateTime().date;
  const currentTime = getCurrentDateTime().time;

  const [showDateTimePickerModal, setShowDatePickerModal] = useState(false);
  const [startDate, setStartDate] = useState(currentDate);
  const [endDate, setEndDate] = useState(currentDate);
  const [startTime, setStartTime] = useState(currentTime);
  const [endTime, setEndTime] = useState(currentTime);
  const [update, setUpdate] = useState(false);
  const [checkTerminals, setCheckedTerminals] = useState({
    'Clinical Trials': true,
    Intervention: true,
    'Search And Explore': true,
  });
  const [alreadyExistUsers, setAlreadyExistUsers] = useState([]);
  const [showYesOrNoModal, setShowYesOrNoModal] = useState(false);
  const [askToRemoveList, setAskToRemoveList] = useState(false);
  const [reset, setReset] = useState(false);
  const [isOrgWillBeCreated, setIsOrgWillBeCreated] = useState(false);
  const [errors, setErrors] = useState({
    licenceDate: false,
    org: false,
    validEmail: false,
    terminal: false,
  });

  const inviteUserStatus = useSelector((state) => state.inviteUserStatus);
  const callingInviteUserStatus = useSelector((state) => state.callingInviteUserStatus);

  const defaultState = () => {
    setShowDatePickerModal(false);
    setStartDate(currentDate);
    setEndDate(currentDate);
    setStartTime(currentTime);
    setEndTime(currentTime);
    setUpdate(false);
    setCheckedTerminals({
      ...{
        'Clinical Trials': true,
        Intervention: true,
        'Search And Explore': true,
      },
    });
    setAlreadyExistUsers([]);
    setShowYesOrNoModal(false);
    setAskToRemoveList(false);
    setOpen(false);
    setReset(false);
    setIsOrgWillBeCreated(false);
    setErrors({
      ...{
        licenceDate: false,
        org: false,
        validEmail: false,
        terminal: false,
      },
    });
    dispatch(resetInviteUserParams());
    dispatch(inviteUserRefreshAction());
  };

  useEffect(() => {
    if (inviteUserStatus.organization_name.enteredOrganization !== '') {
      setIsOrgWillBeCreated(true);
    } else {
      setIsOrgWillBeCreated(false);
    }
  }, [JSON.stringify(inviteUserStatus)]);

  useEffect(() => {
    if (callingInviteUserStatus.flag) {
      if (callingInviteUserStatus.data?.message === 'Below users exists already. Do you want to convert them as enterprise users?') {
        setAlreadyExistUsers([...callingInviteUserStatus.data.emailList]);
        setShowYesOrNoModal(true);
      }

      if (callingInviteUserStatus.data.message === 'User/s Invited Successfully') {
        dispatch(sendNotification({
          type: 'ENTERPRISE_USER_INVITED_SUCCESS',
          notificationType: 'success',
          message: 'User/s Invited Successfully',
          showNotification: true,
        }));
        setReset(true);
        setTimeout(() => defaultState(), 50);
      }
    }
  }, [JSON.stringify(callingInviteUserStatus)]);

  const handleDateAndTimeUpdate = () => {
    setUpdate(true);
    setTimeout(() => [setUpdate(false)], 50);
  };

  const handleReset = () => {
    setReset(true);
    setTimeout(() => defaultState(), 50);
  };

  const handleCheckboxChange = (item) => {
    setCheckedTerminals((prev) => ({
      ...prev,
      [item]: !checkTerminals[item],
    }));
  };

  const handleSubmitClick = (ischangeToEnterpriseUser = false) => {
    const allowedApps = [];
    availableApps.forEach((app) => {
      if (checkTerminals[app]) {
        allowedApps.push(appRoles[app]);
      }
    });

    let flag = true;
    const tempError = {
      licenceDate: false,
      org: false,
      validEmail: false,
      terminal: false,
    };
    if (!inviteUserStatus.organization_name.domain && inviteUserStatus.organization_name.enteredOrganization === '') {
      tempError.org = true;
      flag = false;
    }

    if (!inviteUserStatus.license_date.length) {
      tempError.licenceDate = true;
      flag = false;
    }

    if (!allowedApps.length) {
      tempError.terminal = true;
      flag = false;
    }

    if (!inviteUserStatus.emails.length) {
      tempError.validEmail = true;
      flag = false;
    }

    setErrors({ ...tempError });

    if (flag) {
      dispatch(inviteUserRefreshAction());
      const request = {
        headers: queryString.stringify({
          organization_id: !inviteUserStatus.organization_name.organization_id ? 'create' : inviteUserStatus.organization_name.organization_id,
          domain: inviteUserStatus.organization_name.domain || inviteUserStatus.organization_name.enteredOrganization,
          email: inviteUserStatus.emails.join(','),
          terminals: allowedApps.join(','),
          license_start_date: inviteUserStatus.license_date[0],
          license_end_date: inviteUserStatus.license_date[1],
          ischangeToEnterpriseUser,
        }),
      };
      dispatch(inviteEnterpriseUsers(request));
    }
  };

  const handleNoModal = () => {
    setShowYesOrNoModal(false);
    setAskToRemoveList(true);
  };

  const handleYesClick = () => {
    handleSubmitClick(true);
  };

  const handleRemoveList = () => {
    const allowedApps = [];
    availableApps.forEach((app) => {
      if (checkTerminals[app]) {
        allowedApps.push(appRoles[app]);
      }
    });

    const filtersUsers = inviteUserStatus.emails.filter((email) => !alreadyExistUsers.includes(email));

    if (filtersUsers.length === 0) {
      setReset(true);
      setTimeout(() => defaultState(), 50);
    }

    if (filtersUsers.length) {
      dispatch(inviteUserRefreshAction());
      const request = {
        headers: queryString.stringify({
          organization_id: !inviteUserStatus.organization_name.organization_id ? 'create' : inviteUserStatus.organization_name.organization_id,
          domain: inviteUserStatus.organization_name.domain || inviteUserStatus.organization_name.enteredOrganization,
          email: filtersUsers.join(','),
          terminals: allowedApps.join(','),
          license_start_date: inviteUserStatus.license_date[0],
          license_end_date: inviteUserStatus.license_date[1],
          ischangeToEnterpriseUser: true,
        }),
      };
      dispatch(inviteEnterpriseUsers(request));
    }
  };

  const renderTerminals = () => availableApps.map((item) => (
    <div key={item} className="terminal-checkox">
      <label htmlFor={item}>
        <div aria-hidden onClick={() => handleCheckboxChange(item)}>
          <input
            type="checkbox"
            name={item}
            checked={!!checkTerminals[item]}
          />
          <span className="terminal-name pointer">{item}</span>
        </div>
      </label>
    </div>
  ));

  const renderInviteUsersModal = () => (
    <div>
      <div className="filter-header">
        <div>Invite Enterprise Users</div>
        <SvgLoader onClick={() => handleReset()} pointer width={14.7} height={14.7} svgName="modal-cross" hoverIconName="modal-cross-hover" />
      </div>
      <div className="flex justify-center gap-4 my-6">
        <div className="flex flex-col gap-2">
          <div className="filter-name">Organization Name</div>
          <div>
            <AdminFilterAutoSuggest
              reset={reset}
              filter={{
                label: 'Organization Name',
                type: 'autosuggest-multi',
                key: 'organization_name',
              }}
              type="invite-user"
              className={['autosuggest-multi', 'autosuggest-panel-filter', 'autosuggest-options']}
              update={update}
              messageWhenDropDownIsEmpty="The organization you've entered couldn't be found. A new enterprise organization will be created upon submission."
            />
            {errors.org && <div className="not-valid">Please provide an organization name</div>}
            <div className="info-message">{isOrgWillBeCreated ? 'A new enterprise organization will be created upon submission.' : ''}</div>
          </div>
        </div>
        <div className="flex flex-col gap-2">
          <div className="filter-name">Licence Date</div>
          <div>
            <AdminDateAndTimeInput
              reset={reset}
              filter={{
                label: 'Current Access Date and Time',
                type: 'date-time-range',
                key: 'license_date',
              }}
              dateAndtimeObj={{
                endDate, startDate, endTime, startTime, showDateTimePickerModal, setShowDatePickerModal,
              }}
            />
            {errors.licenceDate && <div className="not-valid">Please enter a valid license  start and end date</div>}
          </div>
        </div>
      </div>
      <div className="mx-12 mb-6">
        <div className="filter-name">Email ID</div>
        <EmailInputBox type="invite-user" reset={reset} update={update} />
        {errors.validEmail && <div className="not-valid">Please enter at least one valid email address</div>}

      </div>
      <div className="terminals-checklist-container">
        <div className="filter-name">Terminal Access</div>
        <div className="terminals-checklist">{renderTerminals()}</div>
        {errors.terminal && <div className="not-valid">Please select at least one terminal</div>}
      </div>
      <div className="filter-buttons">
        <div aria-hidden onClick={() => handleReset()} className="reset-all-btn pointer">Cancel</div>
        <div aria-hidden onClick={() => handleSubmitClick()} className="filter-btn pointer">Submit</div>
      </div>
    </div>
  );

  const renderYesOrNoModal = () => (
    <div>
      <div className="filter-header">
        <div>{' '}</div>
        <SvgLoader onClick={() => setShowYesOrNoModal(false)} pointer width={14.7} height={14.7} svgName="modal-cross" hoverIconName="modal-cross-hover" />
      </div>
      <div className="access-update-text mx-12 min-h-32">
        <div>Below users exists already. Do you want to convert them as enterprise users?</div>
      </div>
      <div className="flex gap-2 mb-6 mx-12 flex-wrap">
        {alreadyExistUsers.map((user) => (
          <div className="py-1.5 px-2.5 user-tag" key={user}>
            <span>{user}</span>
          </div>
        ))}
      </div>
      <div className="filter-buttons">
        <div aria-hidden onClick={() => handleNoModal()} className="reset-all-btn pointer">No</div>
        <div aria-hidden onClick={() => handleYesClick()} className="filter-btn pointer">Yes</div>
      </div>
    </div>
  );

  const renderAskToRemoveList = () => (
    <div>
      <div className="filter-header">
        <div>{' '}</div>
        <SvgLoader onClick={() => setAskToRemoveList(false)} pointer width={14.7} height={14.7} svgName="modal-cross" hoverIconName="modal-cross-hover" />
      </div>
      <div className="access-update-text mx-12 min-h-32">
        <div>Do you want to remove already exited users from the list and then invite user?</div>
      </div>
      <div className="filter-buttons">
        <div aria-hidden onClick={() => setAskToRemoveList(false)} className="reset-all-btn pointer">No</div>
        <div aria-hidden onClick={() => handleRemoveList()} className="filter-btn pointer">Yes</div>
      </div>
    </div>
  );

  return (
    <Modal className="filter-modal-container" closeIcon={false} footer={false} open={open} width={810}>
      {showYesOrNoModal && !askToRemoveList ? renderYesOrNoModal() : null}
      {askToRemoveList && !showYesOrNoModal ? renderAskToRemoveList() : null}
      {
        showDateTimePickerModal && !showYesOrNoModal && !askToRemoveList ? (
          <DateAndTimeRange
            open={showDateTimePickerModal}
            setOpen={setShowDatePickerModal}
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
            startTime={startTime}
            setStartTime={setStartTime}
            endTime={endTime}
            setEndTime={setEndTime}
            handleSubmit={() => handleDateAndTimeUpdate()}
            title="Licence Period"
            inviteUser
          />
        )
          : !showYesOrNoModal && !askToRemoveList ? renderInviteUsersModal() : null
      }
    </Modal>
  );
}
