import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import moment from 'moment';
import { requestOtpAction, requestOtpRefreshAction } from '../LoginModal/logics';
import { getCookie, setCookie, windowHrefToNavigate } from '../../../utils';
import { sendNotification } from '../../../common/components/Notification/logic';
import { closeLoginModalAction, verifyOTPAction } from './logic';
import Loader from '../../../common/components/Loader';
import { removeSession } from '../../../common/container/Status/logic';
import Mixpanel from '../../../utils/mixpanel';
import SvgLoader from '../../../common/components/SvgLoader';
import Header from '../../../common/components/Header';

function LoginModalV2() {
  const dispatch = useDispatch();
  const inputRefs = useRef([]);
  const location = useLocation();

  const inputEmail = getCookie('userEmail') || '';
  const [validOTP, setValidOTP] = useState(false);
  const [otpSent, setOTPSent] = useState(false);
  const [otpResendMessageVisible, setOtpResendMessageVisible] = useState(false);
  const [otp, setOtp] = useState(['', '', '', '', '', '']);

  const open = useSelector((state) => state.isLoginModalOpen.open);
  const requestOtpStatus = useSelector((state) => state.requestOtpStatus);
  const LicenseStatus = useSelector((state) => state.LicenseStatus);
  const verifyOTPSTatus = useSelector((state) => state.verifyOTPSTatus);

  useEffect(() => {
    if (open && getCookie('userEmail') && !getCookie('accessToken')) {
      dispatch(requestOtpRefreshAction());
      dispatch(requestOtpAction({
        headers: queryString.stringify({
          email: inputEmail.toLowerCase(),
        }),
      }));
      setOTPSent(true);
    }
  }, [open]);

  useEffect(() => {
    if (otp.includes('')) {
      setValidOTP(false);
    } else {
      setValidOTP(true);
    }
  }, [otp]);

  useEffect(() => {
    if (otpSent) {
      const timer = setTimeout(() => {
        setOtpResendMessageVisible(true); // Show the message after 60 seconds
      }, 60000); // 60000 milliseconds = 60 seconds

      return () => clearTimeout(timer); // Cleanup timer on unmount or when otpSent changes
    }
    return undefined; // Explicitly return undefined to satisfy ESLint
  }, [otpSent]);

  useEffect(() => {
    if (LicenseStatus.flag && getCookie('freshUser')) {
      Mixpanel.track('user_logged_in_event', {
        action: 'user logged in otp verified successfully',
      });
    }
  }, [JSON.stringify(LicenseStatus)]);

  useEffect(() => {
    if (verifyOTPSTatus.flag) {
      setCookie(moment().add(15, 'days').valueOf(), 'counterExp');
      if (getCookie('freshUser')) {
        setCookie(verifyOTPSTatus.data.response.refresh_token, 'refreshToken');
        if (getCookie('freshUser') === 'individual') {
          setCookie(true, 'showTrialStatusModal');
        } else {
          setCookie(true, 'showTwoWeekStatusModal');
        }
        windowHrefToNavigate(`/callback?access_token=${verifyOTPSTatus.data.response.access_token}`);
      } else {
        setCookie(verifyOTPSTatus.data.response.refresh_token, 'refreshToken');
        windowHrefToNavigate(`/callback?access_token=${verifyOTPSTatus.data.response.access_token}`);
      }
    }

    if (verifyOTPSTatus.error) {
      if (verifyOTPSTatus.data.message === 'Error in OTP verification') {
        let errorMessage;
        if (verifyOTPSTatus.data.error.response.error_description) {
          errorMessage = verifyOTPSTatus.data.error.response.error_description;
        }
        dispatch(sendNotification({
          type: 'OTP_VERIFICATION_FAILED_1',
          notificationType: 'error',
          message: errorMessage || 'Oops something went wrong, please try again',
          showNotification: true,
        }));
      } else {
        let errorMessage;
        if (verifyOTPSTatus.data.error.response.error_description) {
          errorMessage = verifyOTPSTatus.data.error.response.error_description;
        }
        dispatch(sendNotification({
          type: 'OTP_VERIFICATION_FAILED_2',
          notificationType: 'error',
          message: errorMessage || 'Oops something went wrong, please try again',
          showNotification: true,
        }));
        setTimeout(() => {
          dispatch(closeLoginModalAction());
          dispatch(removeSession());
        }, 500);
      }
    }
  }, [JSON.stringify(verifyOTPSTatus)]);

  useEffect(() => {
    if (requestOtpStatus.flag && requestOtpStatus.data.message === 'OTP sent successfully') {
      dispatch(sendNotification({
        type: 'OPT_SENT_SUCCESS',
        notificationType: 'success',
        message: 'OTP sent successfully',
        showNotification: true,
      }));
      Mixpanel.track('otp_sent_successfully_notification_event', {
        action: 'otp sent successfully notification',
      });
    }
  }, [JSON.stringify(requestOtpStatus)]);

  const handleLoginClick = () => {
    setOtpResendMessageVisible(true);
    if (getCookie('freshUser')) {
      if (getCookie('freshUser') === 'individual') {
        setCookie(true, 'showTrialStatusModal');
      } else {
        setCookie(true, 'showTwoWeekStatusModal');
      }
    }
    setOtp(['', '', '', '', '', '']);

    dispatch(requestOtpRefreshAction());
    dispatch(requestOtpAction({
      headers: queryString.stringify({
        email: inputEmail.toLowerCase(),
      }),
    }));
    setOTPSent(true);
    Mixpanel.track('otp_action_event', {
      action: 'Resend OTP button click',
      page: 'Login Page Pop Up',
      misc: 'Resend OTP',
    });
  };

  const verifyOTP = () => {
    if (validOTP) {
      setCookie(inputEmail, 'userEmail');
      setCookie(`${location.pathname}${location.search}`, 'loggedUrl');

      setTimeout(() => {
        dispatch(verifyOTPAction({
          headers: queryString.stringify({
            email: inputEmail,
            otp: otp.join(''),
          }),
        }));
        Mixpanel.track('otp_action_event', {
          action: 'Verify OTP button click',
          page: 'Login Page Pop Up',
          misc: 'Verify OTP',
        });
      }, 100);
    }
  };

  const handleInputChange = (index, value) => {
    if (value.length === 6) {
      setOtp([...value.split('')]);
    } else {
      const newOTP = [...otp];
      newOTP[index] = value.slice(0, 1);
      setOtp(newOTP);
    }
    if (index < inputRefs.current.length - 1 && value !== '') {
      setTimeout(() => {
        inputRefs.current[index + 1].focus();
      }, 100);
    }

    if (index <= inputRefs.current.length - 1 && index > 0 && value === '') {
      setTimeout(() => {
        inputRefs.current[index - 1].focus();
      }, 100);
    }
  };

  const renderOTPinput = () => (
    <div className="otp-container">
      {otp.map((digit, index) => (
        <input
          key={`${digit}-${Math.random()}`}
          type="text"
          value={digit}
          className="otp-number-text"
          onChange={(e) => handleInputChange(index, e.target.value)}
          ref={(ref) => {
            inputRefs.current[index] = ref;
          }}
        />
      ))}
    </div>
  );

  const renderOTP = () => (
    <div>
      { renderOTPinput() }
      <div className="otp-resend-text">
        Didn’t you receive the OTP?
        <span aria-hidden className="otp-resend-text-blue pointer" onClick={() => handleLoginClick()}>
          &nbsp;
          Resend OTP
        </span>
      </div>
      <div className={otpResendMessageVisible ? 'otp-not-received-text-flashing' : 'otp-not-received-text'}>
        Having trouble finding your OTP?
        <br />
        <div className={otpResendMessageVisible ? 'otp-not-received-text-flashing-flashyText' : 'otp-not-received-text'}>Try checking your spam or junk email folder</div>
      </div>
    </div>
  );
  if (!open) {
    return null;
  }

  return (
    <div className="new-bg-img app-container">
      <div className="main-page">
        <Header className="header-home" fromPath="home" />
        <div className="home-bg-img" />
        <div className="ct-home-page-main adjust-login-modal-heigh">
          <div className="ctr-login-modal">
            <Loader loading={!otpSent && !getCookie('userEmail')} height={360}>
              <div className="login-header-text">Login to continue</div>
              {/* <Button className="switch-profile-btn placement-swit-user" onClick={() => handleSwitchProfile()}>Switch Profile</Button> */}

              <SvgLoader width={24} height={24} className="login-cross-icon" svgName="cross-login" onClick={() => dispatch(removeSession())} pointer />
              {
            otpSent
              ? (
                <div className="login-sub-header-text-green flex-col">
                  <span>
                    {'We\'ve sent OTP to your email'}
                  </span>
                  <span style={{
                    marginTop: '5px', fontSize: '15px', fontWeight: 700, letterSpacing: '0.08em',
                  }}
                  >
                    {getCookie('userEmail') || inputEmail}
                  </span>
                </div>
              )
              : <div className="login-sub-header-text">Enter your registered email</div>
          }
              <div>
                {otpSent ? renderOTP() : null}
              </div>
              {
            otpSent ? <div aria-hidden onClick={verifyOTP} className={`${validOTP ? 'login-button pointer' : 'login-button-dissabled'}`}>Verify OTP</div>
              : <div aria-hidden onClick={() => handleLoginClick()} className={`${!validOTP ? 'login-button-dissabled' : 'login-button pointer'}`}>Get OTP</div>
          }

            </Loader>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LoginModalV2;
