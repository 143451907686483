import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import SvgLoader from '../../../common/components/SvgLoader';
import FilterAdmin from '../FilterAdmin';
import { filtersKeys } from '../FilterAdmin/data';
import InviteUserModal from '../InviteUserModal';

export default function AdminSubHeader() {
  const [showFilters, setShowFilters] = useState(false);
  const [filterApplyed, setFilterApplyed] = useState(false);
  const [showInviteModal, setShowInviteModal] = useState(false);

  const userListAdmin = useSelector((state) => state.userListAdmin);
  const adminUserFilter = useSelector((state) => state.adminUserFilter.currentAdminFilter);

  useEffect(() => {
    let flag = false;
    filtersKeys.forEach((item) => {
      if (adminUserFilter[item].length) {
        flag = true;
      }
    });
    setFilterApplyed(flag);
  }, [JSON.stringify(adminUserFilter)]);

  return (
    <div>
      <div className="sub-header-admin">
        <div className="users-count-admin">
          List of users
          {' '}
          {userListAdmin?.total || null}
        </div>
        <div className="button-components">
          <div
            aria-hidden
            className="ct-filters pointer"
            onClick={() => setShowFilters(true)}
          >
            <SvgLoader
              width={20}
              height={20}
              svgName={`${
                !filterApplyed ? 'filter-icon' : 'filter-checked-icon'
              }`}
            />
            <span className="filter-text-name">Filter</span>
          </div>
          <div aria-hidden className="invite-enterprise-admin pointer" onClick={() => setShowInviteModal(true)}>Invite Enterprise User</div>
        </div>
      </div>
      <InviteUserModal open={showInviteModal} setOpen={setShowInviteModal} />
      <FilterAdmin open={showFilters} setOpen={setShowFilters} />
    </div>
  );
}
