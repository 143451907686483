import React from 'react';

export default function AdminDateAndTimeInput({ dateAndtimeObj }) {
  const {
    setShowDatePickerModal,
    startDate,
    startTime,
    endDate,
    endTime,
    setNotify,
    type,
  } = dateAndtimeObj;

  return (
    <div className="date-time-admin-input">
      <div className="date-time-detail adjust-mr-50">
        <div className="date">{startDate}</div>
        <div className="time">{startTime}</div>
      </div>
      <div className="middle-text">to</div>
      <div className="date-time-detail adjust-ml-50">
        <div className="date">{endDate}</div>
        <div className="time">{endTime}</div>
      </div>
      <div
        aria-hidden
        className="datepicker-icon"
        onClick={() => {
          setShowDatePickerModal(true);
          if (setNotify && type === 'edit') {
            setNotify(true);
          }
        }}
      />
    </div>
  );
}
