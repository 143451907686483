/* eslint-disable react/no-danger */
import { Modal } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import SvgLoader from '../../../common/components/SvgLoader';
import { addFeedBackAction, getFeedbackQuestionsAction, setFeedBackOpenModalAction } from './logic';
import Loader from '../../../common/components/Loader';
import { getCookie, setCookie } from '../../../utils';
import { getRefreshTokenAction } from '../../../common/container/Status/logic';
import { setFeedBackAndReferralModalOpenAction } from '../Referral/logic';

let timer = null;
const debounceCall = (callback, debounceTime) => {
  if (timer) {
    clearTimeout(timer);
  }
  timer = setTimeout(() => {
    callback();
  }, debounceTime);
};

export default function Feedback() {
  const dispatch = useDispatch();
  const location = useLocation();

  const [rating, setRating] = useState(0);
  const [likedFeatures, setLikedFeatures] = useState([]);
  const [hasRated, setHasRated] = useState(true);
  const [hasSelectedFeature, setHasSelectedFeature] = useState(true);
  const [submitButtonDisabled, setSubmitButtonDisabled] = useState(false);
  const [enableAutomaticValidation, setEnableAutomaticVAlidation] = useState(false);
  const [alternativeProductInput, setAlternativeProductInput] = useState('');
  const [feedbackAndComment, setFeedbackAndComment] = useState('');
  const [successModal, setSuccessModal] = useState(false);
  const [showLicenceExtendedMessage, setShowLicenceExtended] = useState(false);

  const toggleFeedback = useSelector((state) => state.toggleFeedback.toggleFeedback);
  const feedbackQuestions = useSelector((state) => state.feedbackQuestions);
  const feedbackStatus = useSelector((state) => state.feedbackStatus);

  const defaultState = () => {
    setRating(0);
    setLikedFeatures([]);
    setHasRated(true);
    setHasSelectedFeature(true);
    setSubmitButtonDisabled(false);
    setEnableAutomaticVAlidation(false);
    setAlternativeProductInput('');
    setFeedbackAndComment('');
    setSuccessModal(false);
    dispatch(setFeedBackOpenModalAction(false));
  };

  useEffect(() => {
    if (feedbackStatus.flag) {
      if (feedbackStatus.data.message === 'User feedback added successfully') {
        setSuccessModal(true);
      }

      if (feedbackStatus.data.message === 'User feedback added successfully and license has been extended') {
        setSuccessModal(true);
        setShowLicenceExtended(true);
      }
    }
  }, [JSON.stringify(feedbackStatus)]);

  useEffect(() => {
    if (toggleFeedback && !feedbackQuestions.flag) {
      dispatch(getFeedbackQuestionsAction());
    }
  }, [toggleFeedback]);

  const handleClose = () => {
    if (getCookie('feedbackReferModal') === 'true') {
      dispatch(setFeedBackOpenModalAction(false));
      dispatch(setFeedBackAndReferralModalOpenAction(true));
    } else {
      setCookie('', 'previousTimeStamp');
      dispatch(setFeedBackOpenModalAction(false));
    }
  };

  useEffect(() => {
    if (enableAutomaticValidation) {
      setHasSelectedFeature(likedFeatures.length !== 0);
      setSubmitButtonDisabled(rating === 0 || likedFeatures.length === 0);
    }
  }, [JSON.stringify(likedFeatures), rating]);

  const handleRateIconClick = (index) => {
    setHasRated(true);
    setRating(index);
  };

  const handleValidationAndSubmit = () => {
    if (rating === 0 || likedFeatures.length === 0) {
      setSubmitButtonDisabled(rating === 0 || likedFeatures.length === 0);
      setHasRated(rating !== 0);
      setHasSelectedFeature(likedFeatures.length !== 0);
    } else {
      setSubmitButtonDisabled(false);
      const payload = [];
      feedbackQuestions.data.forEach((question) => {
        const temp = {};
        temp.question_id = question.question_id;
        temp.question = question.question;
        if (question.question.includes('rate')) {
          temp.answer = String(rating);
        } else if (question.question.includes('features')) {
          temp.answer = likedFeatures.join(',');
        } else if (question.question.includes('product which provides')) {
          temp.answer = alternativeProductInput;
        } else {
          temp.answer = feedbackAndComment;
        }
        payload.push(temp);
      });
      dispatch(addFeedBackAction({
        body: {
          feedback: [...payload],
        },
      }));
    }
    setEnableAutomaticVAlidation(true);
  };

  const handleFeatureClick = (feature) => {
    if (likedFeatures.includes(feature)) {
      const tempFeatures = likedFeatures.filter((item) => item !== feature);
      setLikedFeatures([...tempFeatures]);
    } else {
      setLikedFeatures([...likedFeatures, feature]);
    }
  };

  const handleInputChange = (e) => {
    e.preventDefault();
    setAlternativeProductInput(e.target.value);
    e.stopPropagation();
  };

  const handleFeedbackInputChange = (e) => {
    e.preventDefault();
    setFeedbackAndComment(e.target.value);
    e.stopPropagation();
  };

  const handleSuccessModalClose = () => {
    defaultState();
    setSuccessModal(false);
    setCookie(false, 'trialPeriodExpired');
    setCookie(true, 'refreshTokenGetState');
    setCookie(`${location.pathname}${location.search}`, 'loggedUrl');
    debounceCall(() => {
      dispatch(getRefreshTokenAction({
        headers: queryString.stringify({
          refresh_token: getCookie('refreshToken'),
        }),
      }));
    }, 1000);
  };

  const renderQuestions = () => feedbackQuestions.data.map((question, i) => (
    <div key={question.question}>
      <div className="question-text"><div dangerouslySetInnerHTML={{ __html: `${i + 1}. ${question.question}` }} /></div>
      <div>
        {i === 0 ? (
          <div className="mt-3.5 mb-2.5 flex flex-col">
            <div className="flex gap-3">
              {[1, 2, 3, 4, 5].map((index) => (
                <SvgLoader onClick={() => handleRateIconClick(index)} width={30} height={30} svgName={`${rating >= index ? 'rate-selected' : 'rate-not-selected'}`} pointer />
              ))}
            </div>
            <div className={`not-valid ${hasRated ? 'invisible' : 'visible'}`}>
              Please rate Ontosight
              <sup>@</sup>
              {' '}
              Terminal
            </div>
          </div>
        ) : null}
        {i === 1 ? (
          <div className="mt-3.5 mb-2.5 flex flex-col">
            <div className="flex flex-wrap w-full">
              {question.options.map((feature) => (
                <div className="flex items-baseline gap-1 w-1/3">
                  <input className="pointer" checked={likedFeatures.includes(feature)} onClick={() => handleFeatureClick(feature)} type="checkbox" />
                  <div aria-hidden onClick={() => handleFeatureClick(feature)} className="text-3 pl-1 pointer">{feature}</div>
                </div>
              ))}
            </div>
            <div className={`not-valid ${hasSelectedFeature ? 'invisible' : 'visible'}`}>Please select at least one feature</div>
          </div>
        ) : null}
        {i === 2 ? (
          <input value={alternativeProductInput} onChange={handleInputChange} type="text" placeholder="your answer" className="input-1 w-full mt-3.5 mb-10" />
        ) : null}
        {i === 3 ? (
          <textarea value={feedbackAndComment} onChange={handleFeedbackInputChange} type="text" placeholder="your answer" className="input-1 w-full mt-3.5 mb-10" />
        ) : null}
      </div>
    </div>
  ));

  if (successModal) {
    return (
      <Modal className="feedback-modal" footer={false} open={successModal} closeIcon={false} width={668}>
        <div className="p-5 flex flex-col justify-center items-center">
          <div className="flex justify-end w-full">
            <SvgLoader width={34} height={34} svgName="cross" pointer onClick={() => handleSuccessModalClose()} />
          </div>
          <div>
            <SvgLoader width={120} height={118} svgName="success-signup" />
          </div>
          {showLicenceExtendedMessage ? <div className="text-license-update mt-6">Your licence is extended by 15 days</div> : null}
          <div className="mt-10 feedback-success-text">Feedback has been submitted successfully!</div>
          <div className="feedback-participation-text mt-2 mb-14">Thank you for participating in improving the platform.</div>
        </div>
      </Modal>
    );
  }

  return (
    <Modal className="feedback-modal" footer={false} open={toggleFeedback} closeIcon={false} width={668}>
      <div className="feedback-container">
        <div className="feedback-header-section">
          <div className="cross-icon-placement">
            <SvgLoader width={34} height={34} svgName="cross-icon" pointer onClick={() => handleClose()} />
          </div>
          <div>
            <div className="p-7">
              <SvgLoader width={173.92} height={59.18} svgName="ontosight-logo-terminal" />
            </div>
            <div className="header-text flex flex-col pl-7 text-white">
              <span className="text-1">Feedback</span>
              <span className="text-2">Share your experience and help us improve</span>
            </div>
          </div>
          <div>
            <div className="feedback-header-image" />
          </div>
        </div>
        <Loader
          error={feedbackQuestions.error}
          loading={feedbackQuestions.loading}
          noData={feedbackQuestions.data.length === 0}
          height="250px"
          className="white-loader"
          message="No Feedback Questions found"
        >
          <div className="questions-container p-7">
            {renderQuestions()}
            <div className="flex justify-end">
              <div aria-hidden onClick={() => defaultState()} className="py-3.5 px-6 mr-6 pointer btn-hollow-grey">Clear</div>
              <div aria-hidden onClick={() => handleValidationAndSubmit()} className={`py-3.5 px-6 pointer btn-blue-fill ${submitButtonDisabled ? 'bg-slate-400 cursor-not-allowed hover:bg-slate-400' : ''}`}>Submit</div>
            </div>
          </div>
        </Loader>
      </div>
    </Modal>
  );
}
