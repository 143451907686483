import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import queryString from 'query-string';
import useAppSelector from '../../../utils/redux-hook';
import Loader from '../../../common/components/Loader';
import { getTerminalsAction } from '../Selection/logic';
import { getCookie, windowHrefToNavigate } from '../../../utils';
import { appPermission, terminalsPath } from '../../components/Card/data';
import terminalContent from './data';
import Mixpanel from '../../../utils/mixpanel';
import SvgLoader from '../../../common/components/SvgLoader';

function SelectionV2() {
  const dispatch = useDispatch();
  const terminalExporeRef = useRef(null);

  const [recommendedTerminals, setRecommendedTerminals] = useState([]);
  const [exploredTerminals, setExploredTermainals] = useState([]);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [rightBtnDissable, setRightBtnDissable] = useState(false);

  const terminals = useAppSelector((state) => state.terminals);

  const activeTerimainsl = ['ot-intervention-access', 'ot-ct-access', 'ot-search-exp-access', 'influence_app_access', 'xplore_app_access'];

  useEffect(() => {
    if (terminals.flag) {
      const tempRecommendedTermainsl = terminals.data.filter((item) => item.recommended);
      let tempExploredTerminals = terminals.data.filter((item) => !item.recommended);

      // changing the order of terminals shown on UI
      tempExploredTerminals = [tempExploredTerminals[0], tempExploredTerminals[6], tempExploredTerminals[7], ...tempExploredTerminals.slice(1, 6)];
      setRecommendedTerminals([...tempRecommendedTermainsl]);
      setExploredTermainals([...tempExploredTerminals]);
    }
  }, [JSON.stringify(terminals)]);

  let timer = null;
  useEffect(() => {
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(() => {
      dispatch(getTerminalsAction({
        headers: queryString.stringify({
          email: getCookie('userEmail'),
        }),
      }));
    }, 50);
  }, []);

  const checkWords = (words) => {
    if (words.length > 100) {
      let count = 0;
      const wordList = words.split(' ');
      let result = '';

      wordList.forEach((word) => {
        if (count + word.length > 100) {
          return;
        }
        count += word.length + 1;
        result += `${word} `;
      });
      return (
        <div>
          {`${result.trim()}...`}
        </div>
      );
    }
    return words;
  };

  const getTerminalClass = ({ terminal }) => {
    if (!activeTerimainsl.includes(terminal)) {
      return 'comming-soon';
    }

    return 'active';
  };

  const renderTerminalText = (item) => {
    if (getTerminalClass(item) === 'comming-soon') {
      return 'Coming Soon!';
    }

    return null;
  };

  const handlClick = (item) => {
    if (getTerminalClass(item) !== 'comming-soon') {
      Mixpanel.track('terminal_name_event', {
        action: 'terminal card on home page click',
        page: 'Home Page',
        status: 'active',
        terminal_name: appPermission[item.terminal],
      });

      Mixpanel.track('page_exit_event', {
        action: 'page leave event',
        page: 'Home Page',
      });
      windowHrefToNavigate(terminalsPath[item.terminal]);
    } else {
      Mixpanel.track('terminal_name_event', {
        action: 'terminal card on home page click',
        page: 'Home Page',
        status: 'coming soon',
        terminal_name: appPermission[item.terminal],
      });
    }
  };

  const handleScrollLeft = () => {
    if (terminalExporeRef.current) {
      terminalExporeRef.current.scrollLeft -= 100;
      setScrollPosition(terminalExporeRef.current.scrollLeft);
      setRightBtnDissable(false);
      Mixpanel.track('backward_Arrow_click', {
        action: 'left arrow click to scroll "you may like to explore" terminals',
        page: 'Home Page',
      });
    }
  };

  const handleScrollRight = () => {
    if (terminalExporeRef.current) {
      const prevScrollPositoin = scrollPosition;
      terminalExporeRef.current.scrollLeft += 100;

      if (prevScrollPositoin === terminalExporeRef.current.scrollLeft) {
        setRightBtnDissable(true);
      }
      setScrollPosition(terminalExporeRef.current.scrollLeft);
      Mixpanel.track('forward_arrow_click', {
        action: 'right arrow click to scroll "you may like to explore" terminals',
        page: 'Home Page',
      });
    }
  };

  const renderRecommendedCards = () => recommendedTerminals.map((item) => (
    <div className="terminal-card" aria-hidden onClick={() => handlClick(item)}>
      <div className={`app-image ${terminalContent[item.terminal].label}`} />
      <div className="app-name">
        {appPermission[item.terminal]}
      </div>
      <div className="app-detail">
        {checkWords(item.description)}
      </div>
    </div>
  ));

  const renderExploredCards = () => exploredTerminals.map((item) => (
    <div className={`terminal-card-mini ${getTerminalClass(item)}`} aria-hidden onClick={() => handlClick(item)}>
      <div className={`app-image-mini ${terminalContent[item.terminal].label}`}>
        <div className={`${getTerminalClass(item)}-view`}>
          <div className="white-patch" />
          <div className="view-content">
            <div className="view-content-icon">
              <div className="view-content-icon-img" />
            </div>
            <div className="view-content-title">
              {renderTerminalText(item)}
            </div>
          </div>
        </div>
      </div>
      <div className="app-name">
        {appPermission[item.terminal]}
      </div>
      <div className="app-detail">
        {checkWords(item.description)}
      </div>
    </div>
  ));

  return (
    <Loader
      error={terminals.error}
      loading={!terminals.flag}
      noData={terminals.data?.length === 0}
      height="85vh"
      className="black-loader"
    >
      <div className="home-page">
        <div className="home-bg-img">
          <div className="blue-bg-eclips-left" />
          <div className="blue-bg-eclips-right" />
          <div className="blue-bg-eclips-left-bottom" />
          <div className="blue-bg-eclips-right-bottom" />
        </div>
        <div className="home-page-content">
          <div className="unclocked">
            <div className="header">Recommended terminals based on your previous session</div>
            <div className="terminal-container">
              {renderRecommendedCards()}
            </div>
          </div>
          <div className="unclocked">
            <div className="header">You may like to explore </div>
            <div className="terminal-container-explore" ref={terminalExporeRef}>
              {renderExploredCards()}
            </div>
            <div className="terminal-ctrl-button">
              <SvgLoader onClick={handleScrollLeft} width={44} height={44} svgName={scrollPosition === 0 ? 'ter-left' : 'ter-left-bold'} />
              <SvgLoader onClick={handleScrollRight} width={44} height={44} svgName={rightBtnDissable ? 'ter-right' : 'ter-right-bold'} />
            </div>
          </div>
        </div>
      </div>
    </Loader>
  );
}

export default SelectionV2;
