/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import Panel from '../../../common/components/Panel';
import SvgLoader from '../../../common/components/SvgLoader';
import { trackOutSideEvents } from '../../../utils';
import { updateAdminFiltersAction } from '../FilterAdmin/logics';

function AdminFilterMultiSelect({
  filter, reset, onSelectionChange, formValue, setFormValue, updateOrg, orgData = {}, terminalTags = [],
}) {
  const divEl = useRef(null);
  const multiSelectDropdownRef = useRef(null);
  const dispatch = useDispatch();

  const [selectAllSelected, setSelectAllSelected] = useState(false);
  const [currentOptions, setCurrentOptions] = useState([]);
  const [currentKeyOptions, setCurrentKeyOptions] = useState([]);
  const [open, setOpen] = useState(false);
  const { options } = filter;

  const adminUserFilter = useSelector((state) => state?.adminUserFilter?.currentAdminFilter);
  const filtersUpdate = useSelector((state) => state?.filtersUpdate?.upate);

  useEffect(() => {
    if (onSelectionChange) {
      onSelectionChange(currentKeyOptions.join(',')); // Convert array to string
    }
  }, [currentKeyOptions]);
  useEffect(() => {
    if (updateOrg !== 'edit') {
      const tempOptions = [];
      const tempKeys = adminUserFilter[filter.key];

      filter.options.forEach((item) => {
        if (tempKeys.includes(item.value)) {
          tempOptions.push(item.label);
        }
      });

      setCurrentOptions([...tempOptions]);
      setCurrentKeyOptions([...tempKeys]);
    }
  }, [filtersUpdate]);

  useEffect(() => {
    if (terminalTags.length === 0) {
      setCurrentOptions([]);
      setCurrentKeyOptions([]);
    }
  }, [terminalTags]);

  useEffect(() => {
    if (orgData && updateOrg === 'edit') {
      const tempOptions = [];
      const tempKeys = orgData.terminals || [];

      filter.options.forEach((item) => {
        if (tempKeys.includes(item.value)) {
          tempOptions.push(item.label);
        }
      });

      setCurrentOptions([...tempOptions]);
      setCurrentKeyOptions([...tempKeys]);
    }
  }, [orgData]);

  const handleDefault = () => {
    setSelectAllSelected(false);
    setCurrentOptions([]);
    setCurrentKeyOptions([]);
  };

  useEffect(() => {
    if (reset) {
      handleDefault();
    }
  }, [reset]);

  useEffect(() => {
    if (updateOrg === 'edit') {
      setFormValue({
        ...formValue,
        currentKeyOptions,
      });
    }
    dispatch(updateAdminFiltersAction({
      key: filter.key,
      options: currentKeyOptions,
    }));
  }, [JSON.stringify(currentKeyOptions)]);

  useEffect(() => {
    trackOutSideEvents(multiSelectDropdownRef, () => setOpen(false));
  }, []);

  useEffect(() => {
    if (currentOptions.length === options.length && options.length !== 0) {
      setSelectAllSelected(true);
    } else {
      setSelectAllSelected(false);
    }
  }, [JSON.stringify(currentOptions)]);

  const handleOptionClick = (option) => {
    if (currentOptions.indexOf(option.label) > -1) {
      const temp = currentOptions.filter((item) => item !== option.label);
      const tempKeys = currentKeyOptions.filter((item) => item !== option.value);
      setCurrentOptions([...temp]);
      setCurrentKeyOptions([...tempKeys]);
    } else {
      setCurrentOptions([...currentOptions, option.label]);
      setCurrentKeyOptions([...currentKeyOptions, option.value]);
    }
  };

  const handleSelectAllClick = () => {
    if (selectAllSelected) {
      handleDefault();
    } else {
      const temp = options.map((item) => item.label);
      const tempKeys = options.map((item) => item.value);
      setCurrentOptions([...temp]);
      setCurrentKeyOptions([...tempKeys]);
      setSelectAllSelected(!selectAllSelected);
    }
  };

  const handleClick = (e) => {
    setOpen(true);
    e.stopPropagation();
  };

  const handleCrossOptionClick = (e, value) => {
    e.stopPropagation();
    const temp = [];
    const tempKeys = [];
    let valueIndex = -1;

    currentOptions.forEach((item, i) => {
      if (item !== value) {
        temp.push(item);
      }

      if (item === value) {
        valueIndex = i;
      }
    });

    currentKeyOptions.forEach((item, i) => {
      if (i !== valueIndex) {
        tempKeys.push(item);
      }
    });
    setCurrentKeyOptions([...tempKeys]);
    setCurrentOptions([...temp]);
  };

  const renderOptions = () => options?.map((option) => (
    <div aria-hidden key={option.key} onClick={() => handleOptionClick(option)} className={`option ${currentOptions.includes(option.label) ? 'active' : ''}`}>
      <input checked={currentOptions.includes(option.label)} onChange={() => handleOptionClick(option)} className="option-checkbox" type="checkbox" />
      <span className="option-text">{option.label}</span>
    </div>
  ));

  const renderDropdown = () => (
    <div aria-hidden onClick={(e) => e.stopPropagation()} className="multiselecte-dropdown style-for-md" ref={multiSelectDropdownRef}>
      <div className="dropdown-height admin-dropdown-height">
        <div className="multiselecte-default">

          <div aria-hidden className={`multiselecte-default-options ${selectAllSelected ? 'active' : ''}`} onClick={() => handleSelectAllClick()}>
            <input checked={selectAllSelected} onChange={() => handleSelectAllClick()} className="checkbox-select-all-md" type="checkbox" />
            Select All
          </div>
          <div aria-hidden onClick={() => handleDefault()} className="multiselecte-default-options">
            <div>Clear All</div>
          </div>

        </div>
        <div className="option-container admin-option-container">
          {renderOptions()}
        </div>
      </div>
    </div>
  );

  const renderTags = () => currentOptions?.map((item) => (
    <div className="tags" key={item}>
      <div className="tag-label" title={item}>{item}</div>
      <SvgLoader className="cross-icon" onClick={(e) => handleCrossOptionClick(e, item)} width={28} height={28} svgName="cross" />
    </div>
  ));

  return (
    <div
      aria-hidden
      ref={divEl}
      className="dropdown"
    >
      <Panel
        className="panel-dropdown filter-display multi-select-filter"
        onClick={(e) => handleClick(e)}
      >
        {currentOptions?.length
          ? (
            <div className="multi-select-wrap adjust-filter-multiselect">
              <div className="multi-select-div">
                <div className="tags-data">
                  {renderTags()}
                </div>
              </div>
            </div>
          )
          : <div>Select...</div>}
        {!currentOptions.length ? <SvgLoader width={24} height={24} svgName="down-arrow" /> : null}
        {open ? renderDropdown() : null}
      </Panel>
    </div>
  );
}

AdminFilterMultiSelect.propTypes = {
  element: PropTypes.PropTypes.shape({
    spa: PropTypes.bool,
  }),
};

AdminFilterMultiSelect.defaultProps = {
  element: {
    spa: false,
  },
};

export default AdminFilterMultiSelect;
